import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../utils';

const EditModal = ({ isEditModalOpen, toggleEditModal, selectedItem, fetchData }) => {
  const [editedItem, setEditedItem] = useState(selectedItem);
  const [errors, setErrors] = useState({});

  const fields = [
    'assets', 'liabilities', 'income', 'expenses', 'savings',
    'investment_performance', 'cash_flow', 'liquid_assets', 'investments',
    'real_estate', 'personal_property', 'mortgages', 'loans', 'credit_card_debt',
    'other_liabilities'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem({ ...editedItem, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleEditItem = async () => {
    let isError = false;
    const newErrors = {};

    // Check if any fields are empty
    fields.forEach(field => {
      if (!editedItem[field]) {
        newErrors[field] = "This field is required";
        isError = true;
      }
    });

    setErrors(newErrors);
    if (isError) return;

    const formDataToSend = new FormData();
    formDataToSend.append('finance_id', selectedItem.id);
    fields.forEach(field => formDataToSend.append(field, editedItem[field]));

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.updatepersonalfinance}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
        toggleEditModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal centered show={isEditModalOpen} onHide={toggleEditModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit My Personal Finance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="theme-form">
          <h5>Personal Net Worth</h5>
          {fields.map((field, index) => (
            <div className="form-group" key={index}>
              <label className="col-form-label pt-0" htmlFor={field}>
                {field.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </label>
              <input
                className={`form-control ${errors[field] ? 'input-error' : ''}`}
                type="number"
                value={editedItem[field]}
                name={field}
                onChange={handleInputChange}
              />
              {errors[field] && <div className="text-danger">{errors[field]}</div>}
            </div>
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={toggleEditModal}>Cancel</button>
        <button className="btn btn-primary" onClick={handleEditItem}>Submit</button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
