import React, { useState, useEffect, useCallback } from "react";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import API from "../../utils";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';

const AnnualOutcomes = () => {
  const uid = localStorage.getItem("user_id");
  const [visionStatements, setVisionStatements] = useState({});
  const [lifeAreas, setLifeAreas] = useState([]);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_category_from_outcomes}`, 
        formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        });
  
      if (response.data.status === "success") {
        const fetchedLifeAreas = response.data.data;
        setLifeAreas(fetchedLifeAreas);
  
        const existingVisionStatements = {};
        fetchedLifeAreas.forEach(area => {
          if (area.outcomes && area.outcomes.length > 0) {
            existingVisionStatements[area.outcome_id] = {
              id: area.outcomes[0].id,
              statement: area.outcomes[0].reason || '',
              goal_outcomes: area.outcomes[0].goal_outcomes || '',
              completed: area.outcomes[0].is_checked === "1"
            };
          } else {
            existingVisionStatements[area.outcome_id] = {
              statement: '',
              goal_outcomes: '',
              completed: false
            };
          }
        });
        setVisionStatements(existingVisionStatements);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch vision statement error', error);
      toast.error("Failed to fetch vision statements.");
    }
  };
  

  const debouncedApiCall = useCallback(_.debounce(async (key, value) => {
    const visionStatement = value.statement;
    const goalOutcomes = value.goal_outcomes;  
  
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('outcomes_category_id', key);
    formDataToSend.append('reason', visionStatement);
    formDataToSend.append('goal_outcomes', goalOutcomes);  
    formDataToSend.append('status', value.completed ? 1 : 0);
  
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_upadate_outcomes}`,
        formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        });
  
      if (response.data.status === "success") {
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Add vision statement error', error);
      toast.error("Failed to add vision statement.");
    }
  }, 2000), []);
  
  const handleChange = (e, type = "statement") => {
    const { name, value, type: inputType } = e.target;
  
    const updatedStatements = {
      ...visionStatements,
      [name]: {
        ...visionStatements[name],
        [type]: inputType === 'checkbox' ? visionStatements[name][type] : value,
        completed: inputType === 'checkbox' ? e.target.checked : visionStatements[name]?.completed
      }
    };
  
    setVisionStatements(updatedStatements);
    debouncedApiCall(name, updatedStatements[name]);
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className=" align-items-center justify-content-between d-flex">
                  <h5>Goals & Outcomes</h5>
                </div>
              </div>
            </div>
            <div className="card-body mt-3">
              <div className="textnots-container-outcoms mb-3">
                <ul className="textnots-text">
                  <li>
                    <strong>Goal</strong>
                    <p>A broad statement that provides direction, motivation, and purpose. Goals are not usually measurable and can be part of an umbrella spectrum. For example, a goal could be to improve a community through a program.</p>
                  </li>
                  <li>
                    <strong>Outcome</strong>
                    <p>A detailed, measurable statement that outlines the end result of your actions. Outcomes can be observable and speak to what success looks and feels like. For example, an outcome could be making progress toward a school readiness goal.</p>
                  </li>
                </ul>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "5%" }}>Complete</th>
                    <th scope="col" style={{ width: "15%" }}>Goal or Outcome</th>
                    <th scope="col" style={{ width: "15%" }}>Life Area</th>
                    <th scope="col" style={{ width: "65%" }}>This Year’s Major Goal or Outcome</th>
                  </tr>
                </thead>
                <tbody>
                  {lifeAreas.map((area) => (
                    <tr key={area.outcome_id}>
                      <td className="annualoutcome">
                        <input
                          type="checkbox"
                          className="checkbox_animated"
                          name={area.outcome_id}
                          checked={visionStatements[area.outcome_id]?.completed || (area.outcomes && area.outcomes[0]?.is_checked === "1")}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <select
                          name={area.outcome_id}
                          value={visionStatements[area.outcome_id]?.goal_outcomes || ''}
                          onChange={(e) => handleChange(e, "goal_outcomes")}
                          className="form-control"
                        >
                          <option value="">Choose Option</option>
                          <option value="Goal">Goal</option>
                          <option value="Outcome">Outcome</option>
                        </select>
                      </td>
                      <td className="annualoutcome"><b>{area.category_name}</b></td>
                      <td className="annualoutcome">
                        <textarea
                          className="form-control vision-text-area"
                          name={area.outcome_id}
                          placeholder='Insert Here...'
                          value={visionStatements[area.outcome_id]?.statement || ''}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualOutcomes;
