import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap';
import { FaEye, FaRegEdit, } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API, { isSubscribedAccountant } from '../../utils';
import { toast } from 'react-toastify';


const KeyRelationship = () => {
  const navigate = useNavigate()
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const uid = localStorage.getItem("user_id");
  const [searchQuery, setSearchQuery] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editValue, seteditValue] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [gratitudeEntries, setGratitudeEntries] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.getkeyrelationship}/${uid}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });


      if (response.data.status === "success") {
        // setGratitudeEntries(response.data.list);
        const sortedTasks = response.data.list.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setGratitudeEntries(sortedTasks);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch key Relationship error', error);
      // toast.error("Failed to fetch key Relationship.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "", 
    last_contacted: "", 
    birthday: "",
    frequence: "",
    contact_categories: "",
    notes: "",

  });

  useEffect(() => {

    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
return;
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('last_contacted', formData.last_contacted);
    formDataToSend.append('birthday', formData.birthday);
    formDataToSend.append('frequence', formData.frequence);
    formDataToSend.append('contact_categories', formData.contact_categories);
    formDataToSend.append('notes', formData.notes);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addKeyRelationship}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
        setformData({
          name: "",
          email: "",
          phone: "",
          last_contacted: "",
          birthday: "",
          anniversary: "",
          frequence: "",
          contact_categories: "",
          notes: "",
        });
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to add key Relationship.");
    }
 
  };

  const handleEdit = (item) => {
    seteditValue(item)
    setShowEditModal(!showEditModal)

  }

  const handleEditItem = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', editValue.name);
    formDataToSend.append('email', editValue.email);
    formDataToSend.append('phone', editValue.phone);
    formDataToSend.append('last_contacted', editValue.last_contacted);
    formDataToSend.append('birthday', editValue.birthday);
    formDataToSend.append('frequence', editValue.frequence);
    formDataToSend.append('contact_categories', editValue.contact_categories);
    formDataToSend.append('notes', editValue.notes);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.updateKeyRelationship}/${editValue.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
        setShowEditModal(false);

      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Failed to update key Relationship:", error);
    }
  };



  const handleView = async (item) => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.showKeyrelationship}/${item.id}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setSelectedItem(response.data.data);
        toggleViewModal();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('key Relationship view error', error);
      // toast.error("Failed to fetch key Relationship details.");
    }
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDeleteItem = async () => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}${API.ENDPOINTS.deleteKeyRelationship}/${selectedItem.id}`,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
        setIsDeleteModalOpen(false);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Delete key Relationship error", error);
      // toast.error("Failed to delete key Relationship.");
    }
  };


  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  const thStyle = {
    border: "1px solid gray",
    // padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      width: "15%" // Adjust the width as needed
    },
    {
      name: 'Email',
      selector: (row) => (
        <a href={`mailto:${row.email}`}>{row.email}</a>
      ),
      width: "20%" // Adjust the width as needed
    },
    {
      name: 'Phone',
      selector: (row) => (
        <a href={`tel:${row.phone}`}>{row.phone}</a>
      ),
      width: "15%" // Adjust the width as needed
    },
    {
      name: 'Contact Category',
      selector: (row) => row.contact_categories,
      sortable: true,
      width: "30%"
    },
    // {
    //   name: 'Additional Notes',
    //   selector: (row) => row.notes,
    //   sortable: true,
    //   width: "25%" // Adjust the width as needed
    // },
    {
      name: 'Action',
      cell: (row, index) => (
        <>
          <Button className="btn-secondary btn-pill main-edit-view-buttons keyrelation-button" onClick={() => handleView(row)} ><FaEye /></Button>
          <Button className="btn-secondary btn-pill main-edit-view-buttons keyrelation-button" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button variant="danger" className="btn-pill main-delete-buttons keyrelation-button" onClick={() => handleDelete(row)}><MdDelete /></Button>
        </>
      ),
      width: "15%"
    },
];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = gratitudeEntries.filter(entry =>
    (entry.name && entry.name.includes(searchQuery.toLowerCase())) ||
    (entry.email && entry.email.includes(searchQuery.toLowerCase())) ||
    (entry.phone && entry.phone.includes(searchQuery.toLowerCase())) 
  );

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    seteditValue({ ...editValue, [name]: value });

    
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setformData({ ...formData, [name]: value });
   
  };


  
  useEffect(() => {
    const isFormEmpty = Object.values(formData).every(value => value === '');
    setIsSaveButtonDisabled(isFormEmpty);
  }, [formData]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Key Relationships that I Want to Make Sure I Keep in Contact with on a Regular Basis</h5>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <form className="theme-form my-3" >
                  <div className="row">

                    <div className='col-lg-4 col-md-12'>
                      <div className="mt-2 mb-3 form-group">
                        <label><b>Name</b></label>
                        <input type='text' className={`form-control`} name="name" value={formData.name} onChange={handleInputChange} placeholder='Enter Name'></input>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-12'>
                      <div className="mt-2 mb-3 form-group">
                        <label><b>Email</b></label>
                        <input type='email' className={`form-control `} placeholder='Enter Email' value={formData.email} name="email" onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-12'>
                      <div className="mt-2 mb-3 form-group">
                        <label><b>Phone No.</b></label>
                        <input type='number' className={`form-control`} name="phone" placeholder='Enter Phone no' value={formData.phone} onChange={handleInputChange} ></input>
                      </div>
                    </div>

                  

                    <div className='col-lg-4 col-md-12'>
                      <div className="mt-2 mb-3 form-group">
                        <label><b>Last Contacted</b></label>
                        <input className={`form-control `} name="last_contacted" type="date" onChange={handleInputChange} placeholder='Enter Last Contacted' value={formData.last_contacted}></input>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-12'>
                      <div className="mt-2 mb-3 form-group">
                        <label><b>Brithday </b></label>
                        <input className={`form-control`} name="birthday" type="date" onChange={handleInputChange} placeholder='Enter Follow up Date' value={formData.birthday} ></input>
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-12'>
                      <div className="form-group mt-2 mb-3">
                        <label><b>Frequence</b></label>
                        <select
                          className={`form-control `}
                          onChange={handleInputChange}
                          name="frequence"
                          value={formData.frequence}
                        >
                          <option value="">Select Option</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Annually">Annually</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-12'>
                      <div className="form-group mt-2 mb-3">
                        <label><b>Contact Categories</b></label>
                        <select
                          className={`form-control `}
                          onChange={handleInputChange}
                          name="contact_categories"
                          value={formData.contact_categories}
                        >
                          <option value="">Select Option</option>
                          <option value="friendship">Friendship</option>
                          <option value="family">Family</option>
                          <option value="work">Work</option>
                        </select>

                      </div>
                    </div>
                    
                    <div className="form-group mt-2">
                      <span><b>Additional Notes or Notes:</b></span>
                      <textarea
                        className={`form-control `}
                        name='notes'
                        rows="4"
                        value={formData.notes}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="d-flex justify-content-end align-content-center">
                      <button className="btn btn-secondary" onClick={handleSubmit} disabled={isSaveButtonDisabled} >Save</button>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <div className="form-group text-end mb-3 ">
                      <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div >
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    pagination
                    className='keyrelationship-table'
                    persistTableHead={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal centered={true} show={isDeleteModalOpen} onHide={handleDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Key Relationship</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete ?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleDelete}>Cancel</button>
            <button className="btn btn-danger me-1" onClick={handleDeleteItem}>Delete</button>
          </Modal.Footer>
        </Modal>

        {showEditModal && (
          <Modal centered={true} show={showEditModal} onHide={handleEdit}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Key Relationship</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div className="form-group">
                <label>Name</label>
                <input
                  type='text'
                  className={`form-control`}
                  value={editValue.name}
                  name='name'
                  placeholder='Name'
                  onChange={handleEditInputChange}

                />
              </div>
              <div className="mt-2 form-group">
                <label>Email</label>
                <input
                  type='email'
                  className={`form-control `}
                  value={editValue.email}
                  name='email'
                  placeholder='email'
                  onChange={handleEditInputChange}

                />
              </div>
              <div className="mt-2 form-group">
                <label>Phone no.</label>
                <input
                  type='number'
                  className={`form-control `}
                  value={editValue.phone}
                  name='phone'
                  placeholder='Phone no'
                  onChange={handleEditInputChange}
                />
              </div>

             

              <div className="mt-2 mb-3 form-group">
                <label>Last Contacted</label>
                <input
                  className={`form-control `}
                  name="last_contacted"
                  type="date"
                  value={editValue.last_contacted}
                  onChange={handleEditInputChange}
                />
              </div>

              <div className="mt-2 mb-3 form-group">
                <label>Brithday </label>
                <input value={editValue.birthday} className={`form-control `} name="birthday" type="date" onChange={handleEditInputChange} placeholder='Enter Follow up Date'  ></input>
              </div>


              <div className='col-lg-12 col-md-12'>
                <div className="form-group mt-2 mb-3">
                  <label>Frequence</label>
                  <select
                    className={`form-control `}
                    onChange={handleEditInputChange}
                    name="frequence"
                    value={editValue.frequence}
                  >
                    <option value="">Select Option</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Annually">Annually</option>
                  </select>
                </div>
              </div>

              <div className="form-group mb-3">
                <label>Contact Categories:</label>
                <select
                  name='contact_categories'
                  className={`form-control `}
                  value={editValue.contact_categories}
                  onChange={handleEditInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="friendship">Friendship</option>
                  <option value="family">Family</option>
                  <option value="work">Work</option>
                </select>
              </div>


              <div className="form-group mt-2">
                <label>Notes</label>
                <textarea
                  className={`form-control `}
                  name='notes'
                  rows="4"
                  value={editValue.notes}
                  onChange={handleEditInputChange}
                />
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={handleEdit}>Cancel</button>
              <button className="btn btn-primary me-1" onClick={handleEditItem}>Save</button>
            </Modal.Footer>
          </Modal>
        )}

      </div>
      <div className="card-body btn-showcase">
        {isViewModalOpen && (

          <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg" >
            <Modal.Header closeButton>
              <Modal.Title>Key Relationships</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table">
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <th style={thStyle} colSpan={2}>Key Relationships</th>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{selectedItem.name}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{selectedItem.email}</td>
                  </tr>
                  <tr>
                    <td>Phone No</td>
                    <td>{selectedItem.phone}</td>
                  </tr>

               
                  <tr>
                    <td>last_contacted</td>
                    <td>{selectedItem.last_contacted}</td>
                  </tr>
                
                  <tr>
                    <td>Birthday</td>
                    <td>{selectedItem.birthday}</td>
                  </tr>
                 
                  <tr>
                    <td>Frequence</td>
                    <td>{selectedItem.frequence}</td>
                  </tr>
                  <tr>
                    <td>Contact Categories</td>
                    <td>{selectedItem.contact_categories}</td>
                  </tr>

                  <tr>
                    <td>notes</td>
                    <td>{selectedItem.notes}</td>
                  </tr>
                </tbody>
              </table>

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button> 
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default KeyRelationship;
