import React, { useState } from "react";
import axios from 'axios';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../utils";

const ChangePassword = () => {
    const user_id = localStorage.getItem('user_id');
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        new_password: "",
        current_password: "",
        confirm_password: "",
    });

    const [errors, setErrors] = useState({});

    const handleFormpasswordSubmits = async () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('new_password', formData.new_password);
            formDataToSend.append('current_password', formData.current_password);
            formDataToSend.append('confirm_password', formData.confirm_password);
            formDataToSend.append('user_id', user_id);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.changepassword}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            // 'Content-Type': 'multipart/form-data'
                        },
                    });

                if (response.data.status === "success") {
                    // toast.success(response.data.message);
                } else if (response.data.status === "false") {
                    toast.error(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Change Password error', error);
                toast.error("Change Password failed.");
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleFormpasswordSubmits();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: undefined });
    };

    const validateForm = () => {
        let errors = {};

        if (!formData.current_password) {
            errors.current_password = "Current password is required";
        }
        if (!formData.new_password) {
            errors.new_password = "New password is required";
        } else if (formData.new_password.length < 6) {
            errors.new_password = "New password must be at least 6 characters";
        }
        if (formData.new_password !== formData.confirm_password) {
            errors.confirm_password = "new password and Confirm Password do not match";
        }

        return errors;
    };

    return (
        <div className="card">
            <form>
                <div className="card-header">
                    <h5>Change Password</h5>
                </div>
                <div className="card-body">
                    <div className="col-md-12">
                        <div className="form-group mb-3">
                            <label className="form-label">Current Password</label>
                            <input
                                className={"form-control " + (errors.current_password ? "is-invalid" : "")}
                                type="password"
                                name="current_password"
                                value={formData.current_password}
                                onChange={handleInputChange}
                                placeholder="Enter Current Password"
                                onKeyDown={handleKeyDown}
                            />
                            {errors.current_password && <div className="invalid-feedback">{errors.current_password}</div>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-3">
                            <label className="form-label">New Password</label>
                            <input
                                className={"form-control " + (errors.new_password ? "is-invalid" : "")}
                                type="password"
                                name="new_password"
                                value={formData.new_password}
                                onChange={handleInputChange}
                                placeholder="Enter New Password"
                                onKeyDown={handleKeyDown}
                            />
                            {errors.new_password && <div className="invalid-feedback">{errors.new_password}</div>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input
                                className={"form-control " + (errors.confirm_password ? "is-invalid" : "")}
                                type="password"
                                name="confirm_password"
                                value={formData.confirm_password}
                                onChange={handleInputChange}
                                placeholder="Enter Confirm Password"
                                onKeyDown={handleKeyDown}
                            />
                            {errors.confirm_password && <div className="invalid-feedback">{errors.confirm_password}</div>}
                        </div>
                    </div>
                </div>
                <div className="card-footer text-end">
                    <button className="btn btn-primary" type="submit" onClick={handleFormpasswordSubmits}>
                        Update Password
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
