import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Strength from "./personalSwotTabs/strength";
import Weakness from "./personalSwotTabs/weakness";
import Opportunities from "./personalSwotTabs/opportunities";
import Threats from "./personalSwotTabs/threats";
import API, { isSubscribedAccountant } from "../../utils";
import axios from "axios";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PersonalSWOT = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState("Strengths");


    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "Personal SWOT");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getSubCategory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };

    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };
    return (
        <>
            {/* <Breadcrumb parent="Life 360" title="My Vision" /> */}
            <Container fluid={true} >
                <Tabs
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                    defaultActiveKey="Strengths"
                    id="justify-tab-example"
                    className="border-tab nav-primary  mb-3"
                    justify
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                            {getTabContent(tab.sub_category)}
                        </Tab>
                    ))}
                </Tabs>
            </Container >
        </>
    );

}
const getTabContent = (tabName) => {
    switch (tabName) {
        case "Strengths":
            return <Strength/>;
        case 'Weknesses':
            return <Weakness/> ;
        case 'Opportunities':
            return <Opportunities/>;
        case 'Threts':
            return <Threats/>;
        default:
            return null;
    }
};


export default PersonalSWOT;
