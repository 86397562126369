import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { Modal} from 'react-bootstrap';
import { FaEye, FaRegEdit } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import axios from 'axios';
import { toast } from "react-toastify";
import { format } from 'date-fns';
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
const LifeGoal = () => {
  
  const navigate = useNavigate()
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    life_goal: "",
    type: "",
    actions_needed_to_achieve_my_life_goal: ""
  });

  const [editFormData, setEditFormData] = useState({
    life_goal: "",
    actions_needed_to_achieve_my_life_goal: "",
    type: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    life_goal: "",
    actions_needed_to_achieve_my_life_goal: "",
    type: ""
  });
  const validateLifeGoal = (value) => {
    if (!value) {
      return "This field is required.";
    }
    return "";
  };

  const validateActions = (value) => {
    if (!value) {
      return "This field is required.";
    }
    return "";
  };

  const validateType = (value) => {
    if (!value) {
      return "This field is required.";
    }
    return "";
  };
  const handleLifeGoalChange = (e) => {
    const value = e.target.value;
    setEditFormData({ ...editFormData, life_goal: value });
    const error = validateLifeGoal(value);
    setValidationErrors((prevState) => ({
      ...prevState,
      life_goal: error
    }));
  };

  const handleActionsChange = (e) => {
    const value = e.target.value;
    setEditFormData({ ...editFormData, actions_needed_to_achieve_my_life_goal: value });
    const error = validateActions(value);
    setValidationErrors((prevState) => ({
      ...prevState,
      actions_needed_to_achieve_my_life_goal: error
    }));
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setEditFormData({ ...editFormData, type: value });
    const error = validateType(value);
    setValidationErrors((prevState) => ({
      ...prevState,
      type: error
    }));
  };
  useEffect(() => {
      
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
return;
    }
  
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const handleClose = () => setShowModal(false); 

  const [lifeGoals, setLifeGoals] = useState([]);
  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.get_life_goalsbyuser}/${uid}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        }
      });

      const data = response.data;
      if (data.status === "success") {
        setLifeGoals(data.data);
      } else {
        console.error("Failed to fetch upcoming actions");
      }
    } catch (error) {
      console.error("Error fetching upcoming actions:", error.message);
      toast.error("Error fetching upcoming actions");
    }
  };

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };


  const handleDelete = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteItem = async () => {
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.deletelifegoal}/${selectedItem.id}`,
        null, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchTasks();
        setIsDeleteModalOpen(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting life goal:", error);
      toast.error("An error occurred while deleting. Please try again.");
    }
  };

  const handleEdit = (item) => {
    setEditFormData(item);
    setSelectedItem(item);
    setShowEditModal(true);
    setValidationErrors({});
  };

  const handleEditItem = async () => {
    const lifeGoalError = validateLifeGoal(editFormData.life_goal);
    const actionsError = validateActions(editFormData.actions_needed_to_achieve_my_life_goal);
    const typeError = validateType(editFormData.type);

    if (lifeGoalError || actionsError || typeError) {
      setValidationErrors({
        life_goal: lifeGoalError,
        actions_needed_to_achieve_my_life_goal: actionsError,
        type: typeError
      });
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('life_goal', editFormData.life_goal);
    formDataToSend.append('achieve_life_goal', editFormData.actions_needed_to_achieve_my_life_goal);
    formDataToSend.append('type', editFormData.type);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.updatelifegoal}/${editFormData.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchTasks();
        setShowEditModal(false); 
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating life goal:", error);
      toast.error("An error occurred while updating. Please try again.");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = lifeGoals.filter(entry =>
    entry.actions_needed_to_achieve_my_life_goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.life_goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };



  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };

  const columns = [
    {
      name: 'Actions Needed to Achieve Goal',
      selector: (row) => row.actions_needed_to_achieve_my_life_goal,
      sortable: true,
    },
    {
      name: 'Life Goal',
      selector: (row) => row.life_goal,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: ' Date ',
      selector: (row) => format(new Date(row.created_at), 'yyyy-MM-dd'),
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)}><FaEye /></Button>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button variant="danger" className="btn-pill main-delete-buttons mx-1" onClick={() => handleDelete(row)}>
            <MdDelete />
          </Button>
        </>
      ),
    },
  ];


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (validationErrors[name]) {
      setValidationErrors({
        ...validationErrors,
        [name]: ''
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.life_goal || !formData.life_goal.trim()) {
      errors.life_goal = 'This Field is required';
    }
    if (!formData.actions_needed_to_achieve_my_life_goal || !formData.actions_needed_to_achieve_my_life_goal.trim()) {
      errors.actions_needed_to_achieve_my_life_goal = 'This Field is required';
    }
    if (!formData.type || !formData.type.trim()) {
      errors.type = 'This Field is required';
    }
    return errors;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('life_goal', formData.life_goal);
      formDataToSend.append('achieve_life_goal', formData.actions_needed_to_achieve_my_life_goal);
      formDataToSend.append('type', formData.type);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_life_goal}`, formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        });

        if (response.data.status === 'success') {
          // toast.success(response.data.message);
          fetchTasks();
          handleClose(); // Close modal after successful submission
          setFormData({
            life_goal: '',
            actions_needed_to_achieve_my_life_goal: '',
            type: ''
          });
          setValidationErrors({});
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Add life goal error', error);
        toast.error('Failed to add life goal.');
      }
    } else {
      setValidationErrors(errors);
    }
  };


  return (
    <div>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Life Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Life Goal</label>
            <input type="text"
              className={`form-control ${validationErrors.life_goal ? 'is-invalid' : ''}`}
              name="life_goal" value={editFormData.life_goal}
              onChange={handleLifeGoalChange} />
            {validationErrors.life_goal && <div className="invalid-feedback">{validationErrors.life_goal}</div>}
          </div>

          <div className="form-group mt-3">
            <label>Actions Needed to Achieve My Life Goal</label>
            <textarea
              type="text"
              rows="4"
              className={`form-control ${validationErrors.actions_needed_to_achieve_my_life_goal ? 'is-invalid' : ''}`}
              name="actions_needed_to_achieve_my_life_goal"
              value={editFormData.actions_needed_to_achieve_my_life_goal}
              onChange={handleActionsChange}
            />
            {validationErrors.actions_needed_to_achieve_my_life_goal
              && <div className="invalid-feedback">{validationErrors.actions_needed_to_achieve_my_life_goal}</div>}
          </div>



          <div className="form-group mt-3">
            <label>Type</label>
            <select
              className={`form-control digits ${validationErrors.type ? 'is-invalid' : ''}`}
              name="type"
              value={editFormData.type}
              onChange={handleTypeChange}
            >
              <option value="">Select option</option>
              <option value="Financial">Financial</option>
              <option value="Fitness">Fitness</option>
              <option value="Personal">Personal</option>
            </select>
            {validationErrors.type && <div className="invalid-feedback">{validationErrors.type}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleEditItem()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-body btn-showcase">
        {isViewModalOpen && ( // Render view modal when state is true
          <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg" >
            <Modal.Header closeButton>
              <Modal.Title>Life Goals</Modal.Title>
            </Modal.Header>
            <Modal.Body>


              <table className="table">

                <thead>


                </thead>
                <tbody>
                  <tr>
                    <th style={thStyle} colSpan={2}>Life Goals</th>
                  </tr>

                  <tr>
                    <td>Life Goal</td>
                    <td>{selectedItem.life_goal}</td>
                  </tr>

                  <tr>
                    <td>Date</td>
                    <td>{selectedItem && format(new Date(selectedItem.created_at), 'yyyy-MM-dd')}</td>
                  </tr>
                  <tr>
                    <td>Actions Needed to Achieve My Life Goal</td>
                    <td>{selectedItem.actions_needed_to_achieve_my_life_goal}</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{selectedItem.type}</td>
                  </tr>

                </tbody>
              </table>

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button> {/* Close button */}
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <Modal show={isDeleteModalOpen} onHide={toggleDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Life Goals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteItem}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        <div className="col-sm-12 ">
          <div className="card mt-4">
            <div className="card-header">
              <div className="row">
                <div className=" align-items-center justify-content-between d-flex">
                  <h5>Life Goals</h5>
                </div>
              </div>
            </div>
            <form className="theme-form m-25" onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-lg-4">
                  <label>Life Goal</label>
                  <input
                    type="text"
                    placeholder="Enter Life Goal"
                    className={`form-control ${validationErrors.life_goal ? 'is-invalid' : ''}`}
                    name="life_goal"
                    value={formData.life_goal}
                    onChange={handleInputChange}
                  />
                  {validationErrors.life_goal && <div className="text-danger">{validationErrors.life_goal}</div>}
                </div>
                <div className="form-group col-lg-4">
                  <label>Actions Needed to Achieve My Life Goal</label>
                  <textarea
                    className={`form-control ${validationErrors.actions_needed_to_achieve_my_life_goal ? 'is-invalid' : ''}`}
                    rows="4"
                    placeholder="Enter Actions Needed to Achieve My Life Goal"
                    name="actions_needed_to_achieve_my_life_goal"
                    value={formData.actions_needed_to_achieve_my_life_goal}
                    onChange={handleInputChange}
                  />
                  {validationErrors.actions_needed_to_achieve_my_life_goal && <div className="text-danger">{validationErrors.actions_needed_to_achieve_my_life_goal}</div>}
                </div>
                <div className="form-group col-lg-4">
                  <label>Type</label>
                  <select
                    className={`form-control ${validationErrors.type ? 'is-invalid' : ''}`}
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="Financial">Financial</option>
                    <option value="Fitness">Fitness</option>
                    <option value="Personal">Personal</option>
                  </select>
                  {validationErrors.type && <div className="text-danger">{validationErrors.type}</div>}
                </div>
              </div>
              <div className="d-flex justify-content-end align-content-center">
                <Button variant="primary" type="submit">Save</Button>
              </div>
            </form>

            <div className="col-md-12">
              <div className="card-body ">
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <div className="form-group text-end mb-3 ">
                      <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div className="lifegoals-table">
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    className="lifegoal-table"
                    pagination
                  />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );

}

export default LifeGoal;
