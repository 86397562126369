import React, { Fragment, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { Spinner } from "react-bootstrap";
import API from '../../src/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ReportAccountability = () => {
    const uid = localStorage.getItem("user_id");
    const [chartData, setChartData] = useState([
        ['Category', 'Value', { role: 'style' }, { role: 'annotation' }],
    ]);
    const [loading, setLoading] = useState(true);  

//     useEffect(() => {
//         if (!isSubscribedAccountant()) {
//             navigate("/users/userEdit");
//             window.location.reload();
// return;
//         }
//     }, []);

    // Define an array of colors for the bars
    const barColors = ['#FF5733', '#33FF57', '#3357FF', '#F1C40F', '#8E44AD', '#E67E22', '#1F618D', '#D35400'];

    const options = {
        hAxis: {
            title: 'Life Area',
            slantedText: false,
            textStyle: {
                fontSize: 10,
            },
            titleTextStyle: {
                fontSize: 14,
            },
            maxAlternation: 1,
            showTextEvery: 1, 
        },
        vAxis: {
            title: 'Planning Progress Complete %',
            minValue: 0,
            textStyle: {
                fontSize: 12, 
            },
            titleTextStyle: {
                fontSize: 14,
            },
        },
        chartArea: {
            width: '95%',
            height: '75%',
        },
        annotations: {
            alwaysOutside: true,
            textStyle: {
                fontSize: 14,
                color: '#000',
                auraColor: 'none',
            },
        },
        tooltip: {
            isHtml: true,
            trigger: 'focus',
            textStyle: {
                fontSize: 12,
                color: '#ffff',
                auraColor: 'none',
            },
            formatter: (value) => `<b>${value}%</b>`,
        },
        legend: {
            position: 'none',
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchChartData();
    }, []);

    const fetchChartData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_pos_graph_data}`, formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                const data = response.data.data;
                const formattedData = [['Category', 'Progress', { role: 'style' }, { role: 'annotation' }]]; // Added 'style' role for color
                let colorIndex = 0;
                for (const [category, value] of Object.entries(data)) {
                    formattedData.push([category, Number(value), barColors[colorIndex % barColors.length], `${value}%`]);
                    colorIndex++;
                }
                setChartData(formattedData);
            } else {
                toast.error(response.data.message || 'Failed to fetch chart data');
            }
        } catch (error) {
            console.error('Error fetching chart data:', error);
            toast.error('Error fetching chart data');
        } finally {
            setLoading(false);  // Set loading to false after fetching data
        }
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="chart-container" style={{ width: '100%', height: '400px' }}>
                            {loading ? (  // Show loader while loading
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            ) : (
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={windowWidth < 768 ? '300px' : '400px'}
                                    data={chartData}
                                    options={options}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ReportAccountability;
