import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from 'lodash/debounce';
import API from "../../utils";
import { useNavigate } from "react-router-dom";
import MyLoader from "../common/myloader";
import { Dropdown } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Life360Id = () => {
  const componentRef = useRef();
  const [data, setData] = useState({});
  const uid = localStorage.getItem("user_id");
  const [userName, setUserName] = useState("");

  const tableStyle = {
    border: "2px solid black",
    borderCollapse: "collapse",
    width: "100%",
  };

  const thStyle = {
    border: "2px solid black",
    padding: "8px",
    textAlign: "left",
    background: "#0070c0",
    color: "white",
    width: "20%",
  };

  const tdStyle = {
    border: "2px solid black",
    textAlign: "left",
  };
  const textAreaStyle = {
    width: "100%",
    height: "100%",
    border: "none",
    boxSizing: "border-box",
    padding: "8px",
    fontSize: "14px",
    resize: "none",

  };

  const [formData, setFormData] = useState({
    ratting1: "",
    ratting2: "",
    ratting3: "",
    sollution1: "",
    sollution2: "",
    sollution3: "",
    vision: '',
    service: '',
    googleSearch: '',
    motto: '',
    promise: '',
    alterEgo: '',
    achieveGoals: '',
    resource: '',
    uniqueness: '',
    health: '',
    love: '',
    financial: '',
    travel: '',
    career: '',
    business: '',
    continuedEducation: '',
    personal: '',
    house: '',

  });

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_data_dashboard_chart_page}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === "success") {
        setData(response.data.data);
      } else {
        toast.error(response.data.message || "Failed to fetch tasks");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      // toast.error("Failed to fetch tasks. Please try again later.");
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const renderAffirmations = () => {
    if (data["affirmations"] && data["affirmations"][0] && data["affirmations"][0].affirmation) {
      try {
        const affirmationArray = JSON.parse(data["affirmations"][0].affirmation);

        if (Array.isArray(affirmationArray) && affirmationArray.length > 0) {
          const filteredAffirmations = affirmationArray.filter(item => item && item.trim() !== "");

          if (filteredAffirmations.length > 0) {
            return (
              <ul className="affirmation_ul">
                {filteredAffirmations.map((item, index) => (
                  <li
                    className="dashboard-li"
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#d9e1f2" : "white",
                      color: index % 2 === 0 ? "black" : "black",
                    }}
                  >
                    {index + 1}. {item}
                  </li>
                ))}
              </ul>
            );
          } else {
            return <div>Answer not available</div>;
          }
        } else {
          return <div>Answer not available</div>;
        }
      } catch (error) {
        console.error("Error parsing affirmation data:", error);
        return <div>Answer not available</div>;
      }
    }
    return <div>No affirmations available</div>;
  };

  const renderCoreValues = () => {
    if (data['personal_os_core_value']) {
      const filteredValues = data['personal_os_core_value'].filter(item => item.top5_check === "1");

      return filteredValues.map((item, index) => (
        <React.Fragment key={index}>
          <tr>
            <td style={{ backgroundColor: '#d9e1f2', color: 'black',width:'199px' , padding:' 5px'}}>{index + 1}. {item.question}</td>
          </tr>
          <tr>
            <td style={{ backgroundColor: 'white', color: 'black' }}>{item.description}</td>
          </tr>
        </React.Fragment>
      ));
    }

    return (
      <tr>
        <td colSpan="2">No data available</td>
      </tr>
    );
  };
  const getNestedData = (category, key, subKey) => {
    if (subKey) {
      return data[category] && data[category][subKey] && data[category][subKey][0]?.[key] || "N/A";
    }
    return data[category] && data[category][0]?.[key] || "N/A";
  };

  const rendermylifemanifesto = () => {
    if (data['my_life_menifesto']) {
      const answer = data['my_life_menifesto'].answer;
      return <div>{answer}</div>;
    }
    return <div>N/A</div>;
  };

  const rendermystrengths = () => {
    if (data['my_strength']) {
      const answer = data['my_strength'].answer;
      return <div>{answer}</div>;
    }
    return <div>N/A</div>;
  };

  const rendermyweakness = () => {
    if (data['my_weaknesses']) {
      const answer = data['my_weaknesses'].answer;
      return <div>{answer}</div>;
    }
    return <div>N/A</div>;
  };

  const getmypurpose = () => {
    if (data['my_purpose']) {
      const answer = data['my_purpose'].answer;
      return <div>{answer}</div>;
    }
    return <div>N/A</div>;
  };

  const getmymission = () => {
    if (data['my_mission']) {
      const answer = data['my_mission'].answer;
      return <div>{answer}</div>;
    }
    return <div>N/A</div>;
  };

  const rendermyverybigdream = () => {
    if (data['my_very_big_dream']) {
      const answer = data['my_very_big_dream'].answer;
      return <div>{answer}</div>;
    }
    return <div>N/A</div>;
  };

  const rendertopfivecorevalues = () => {
    if (data['life_goal'] && data['life_goal'].length > 0) {
      const filteredGoals = data['life_goal'].filter(goal => goal.answer.trim() !== '');

      if (filteredGoals.length > 0) {
        return (
          <div>
            {filteredGoals.map((goal, index) => (
              <div
                key={goal.id}
                style={{
                  backgroundColor: index % 2 === 1 ? 'white' : '#d9e1f2',
                  color: 'black',
                  padding: '10px',
                  margin: '5px 0',
                }}
              >
                {goal.answer}
              </div>
            ))}
          </div>
        );
      }
    }
    return <div>N/A</div>;
  };

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const user_id = localStorage.getItem('user_id');
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.getuserdetails}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          const userData = response.data.user_data;
          setUserName(userData.user_name || "User");
        } else {
          console.error(response.data.message);
          localStorage.removeItem("token");
          localStorage.removeItem("login");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_name");
          localStorage.removeItem("email");
          localStorage.removeItem("authenticated");
          localStorage.removeItem("auth0_profile");
          localStorage.removeItem("selectedCoreValues");
          localStorage.removeItem("selectedFiveCoreValues");
          localStorage.removeItem("profileURL");
          localStorage.removeItem("profile");
          localStorage.removeItem("formData");
          localStorage.removeItem("checked_items");
          localStorage.removeItem("lifeWheelFormData");
          localStorage.removeItem("loveLoatheFields");
          localStorage.removeItem("loveLoatheRemoveTopHated");
          localStorage.removeItem("comfortZoneFormData");
          localStorage.removeItem("googlelogin");

          navigate(`/login`);
        }
      } catch (error) {
     }
    };

    fetchData();
  }, []);

  const debouncedPostDataToAPI = useCallback(
    debounce(async (heading, answer) => {
      const formDataToSend = new FormData();
      formDataToSend.append("report_name", heading);
      formDataToSend.append("challenge", answer);
      formDataToSend.append("user_id", uid);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.myReport}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
        } else {
          toast.error(response.data.message || "Failed to post data");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        toast.error("Failed to post data. Please try again later.");
      }
    }, 1000), // Debounce delay
    [uid] // Dependencies
  );

  const handleFormDataChange = (key, value) => {
    setFormData(prevData => ({
      ...prevData,
      [key]: value
    }));

    let heading = '';
    let answer = value;

    switch (key) {
      case 'ratting1':
        heading = 'Current Life Rating 1';
        break;
      case 'sollution1':
        heading = 'Potential Solution 1';
        break;
      case 'ratting2':
        heading = 'Current Life Rating 2';
        break;
      case 'sollution2':
        heading = 'Potential Solution 2';
        break;
      case 'ratting3':
        heading = 'Current Life Rating 3';
        break;
      case 'sollution3':
        heading = 'Potential Solution 3';
        break;
      case 'vision':
        heading = 'My Vision';
        break;
      case 'service':
        heading = 'Those I Want To Serve';
        break;
      case 'googleSearch':
        heading = 'MY 2 WORD GOOGLE SEARCH';
        break;
      case 'motto':
        heading = 'MY LIFE MOTTO';
        break;
      case 'promise':
        heading = 'My Promise To Myself';
        break;
      case 'alterEgo':
        heading = 'My Super Hero Alter-Ego';
        break;
      case 'achieveGoals':
        heading = 'The 2-3 Most Important Actions Needed To Achieve My Life Goal';
        break;
      case 'resource':
        heading = 'Other Resource Or Support I Will Need Later To Achieve My Life Goal';
        break;
      case 'uniqueness':
        heading = 'My Uniqueness';
        break;
      case 'health':
        heading = 'Health/Fitness';
        break;
      case 'love':
        heading = 'Love';
        break;
      case 'financial':
        heading = 'Financial';
        break;
      case 'travel':
        heading = 'Travel';
        break;
      case 'carrer':
        heading = 'Career';
        break;
      case 'business':
        heading = 'Business';
        break;
      case 'continuedEducation':
        heading = 'Continued Education';
        break;
      case 'personal':
        heading = 'Personal';
        break;
      case 'house':
        heading = 'House/Home';
        break;


      default:
        heading = 'Default Heading';
    }

    debouncedPostDataToAPI(heading, answer);
  };

  const [loading, setLoading] = useState(false);

  const fetchFormData = async () => {
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_data_from_report}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const data = response.data.data.reduce((acc, item) => {
          switch (item.report_name) {
            case 'Current Life Rating 1':
              acc.ratting1 = item.challenges;
              break;
            case 'Potential Solution 1':
              acc.sollution1 = item.challenges;
              break;
            case 'Current Life Rating 2':
              acc.ratting2 = item.challenges;
              break;
            case 'Potential Solution 2':
              acc.sollution2 = item.challenges;
              break;
            case 'Current Life Rating 3':
              acc.ratting3 = item.challenges;
              break;
            case 'Potential Solution 3':
              acc.sollution3 = item.challenges;
              break;
            case 'Vision':
              acc.vision = item.challenges;
              break;
            case 'Those I Want To Serve':
              acc.service = item.challenges;
              break;
            case 'The 2-3 Most Important Actions Needed To Achieve My Life Goal':
              acc.achieveGoals = item.challenges;
              break;
            case 'Other Resource Or Support I Will Need Later To Achieve My Life Goal':
              acc.resource = item.challenges;
              break;
            case 'My Uniqueness':
              acc.uniqueness = item.challenges;
              break;
            case 'My Promise To Myself':
              acc.promise = item.challenges;
              break;
            case 'My Super Hero Alter-Ego':
              acc.alterEgo = item.challenges;
              break;
            case 'MY 2 WORD GOOGLE SEARCH':
              acc.googleSearch = item.challenges;
              break;
            case 'MY LIFE MOTTO':
              acc.motto = item.challenges;
              break;
            case 'Health/Fitness':
              acc.health = item.challenges;
              break;

            case 'Love':
              acc.love = item.challenges;
              break;
            case 'Financial':
              acc.financial = item.challenges;
              break;
            case 'Travel':
              acc.travel = item.challenges;
              break;
            case 'Career':
              acc.career = item.challenges;
              break;
            case 'Business':
              acc.business = item.challenges;
              break;

            case 'Continued Education':
              acc.continuedEducation = item.challenges;
              break;
            case 'Personal':
              acc.motto = item.challenges;
              break;
            case 'House/Home':
              acc.house = item.challenges;
              break;

            default:
              break;
          }
          return acc;
        }, { ...formData });

        setFormData(data);
      } else {
        toast.error(response.data.message || "Failed to post data");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Failed to post data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormData();
  }, []);

  const printDocument = () => {
    html2canvas(componentRef.current, { scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("my_life_plan.pdf");
    });
  };

  const handleDownloadExcel = async () => {
    const csvRows = [];

    // Add header row
    csvRows.push([
      'My Core Value',
      'My Purpose',
      'My Mission',
      'Current Life Rating',
      'Potential Solution',
      'Vision',
      'Service',
      'Google Search',
      'Motto',
      'Alter Ego',
      'Promise',
      'Role Model',
      'Uniqueness',
      'Strengths',
      'Weaknesses',
      'Big Dream',
      'Life Goals',
      'Actions',
      'Resource',
      'Health',
      'Love',
      'Financial',
      'Travel',
      'Career',
      'Business',
      'Continued Education',
      'Personal',
      'House'
    ].join(','));




    // Add data rows
    const dataRow = [
      renderCoreValues(),
      getmypurpose(),
      getmymission(),
      formData.rating1,
      formData.solution1,
      formData.vision,
      formData.service,
      formData.googleSearch,
      formData.motto,
      formData.alterEgo,
      formData.promise,
      getNestedData('categories_data', 'answer', 'My Real Life Role Model'),
      formData.uniqueness,
      getNestedData('categories_data', 'answer', 'My Strength'),
      getNestedData('categories_data', 'answer', 'My Weaknesses'),
      rendermyverybigdream(),
      rendertopfivecorevalues(),
      formData.achieveGoals,
      formData.resource,
      formData.health,
      formData.love,
      formData.financial,
      formData.travel,
      formData.career,
      formData.business,
      formData.continuedEducation,
      formData.personal,
      formData.house
    ];
    csvRows.push(dataRow.join(','));

    // Create a blob from the CSV string
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'my_life_plan.csv');
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsDisabled(event.target.checked);
  };

  const disabledSectionStyle = {
    backgroundColor: '#e0e0e0',
    color: '#888',
    pointerEvents: 'none', // Disables user interaction
    opacity: 0.6,         // Visually show the section as disabled
  };

  return (
    <MyLoader active={loading} >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-12 align-items-center justify-content-between d-flex">
                    <h5>My Life Plan</h5>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Download My Life Plan
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="lifeid p-1">
                        <Dropdown.Item as="button" className=" my-2 btn btn-success" onClick={printDocument}>
                          Download Pdf
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className=" my-2 btn btn-success" onClick={handleDownloadExcel}>
                          Download Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>


              <div className="scrolls mt-4" ref={componentRef}>
                <div className="col-md-12 align-items-center d-flex justify-content-between heading-reports">
                  <h5>{userName} Personal Development & Clarity Plan</h5>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox_animated"
                      id="disableSectionCheckbox"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="disableSectionCheckbox">Disable this section</label>
                  </div>
                </div>

                <div className="form-section" style={isDisabled ? disabledSectionStyle : {}}>
                  <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>My Core Value</th>
                      <th style={thStyle}>My Purpose</th>
                      <th style={thStyle}>My Mission</th>
                      <th style={thStyle}>Current Life Rating</th>
                      <th style={thStyle}>Potential Solution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle} rowSpan={4}>{renderCoreValues()}</td>
                      <td style={tdStyle}>
                        {getmypurpose()}
                      </td>
                      <td style={tdStyle}>
                        {getmymission()}
                      </td>
                      <td style={tdStyle} >  <textarea
                        style={textAreaStyle}
                        value={formData.ratting1}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('ratting1', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                      <td style={tdStyle} >  <textarea
                        style={textAreaStyle}
                        value={formData.sollution1}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('sollution1', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>

                    <tr>
                      <th style={thStyle}>My Vision</th>
                      <th style={thStyle}>Those I Want To Serve</th>
                      <th style={thStyle}>Current Life Rating</th>
                      <th style={thStyle}>Potential Solution</th>
                    </tr>
                    <tr>
                      <td style={tdStyle} >
                        <textarea
                          style={textAreaStyle}
                          value={formData.vision}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('vision', e.target.value)}
                          disabled={isDisabled}

                        />
                      </td>
                      <td style={tdStyle}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.service}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('service', e.target.value)}
                          disabled={isDisabled}

                        />
                      </td>
                      <td style={tdStyle} >
                        <textarea
                          style={textAreaStyle}
                          value={formData.ratting2}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('ratting2', e.target.value)}
                          disabled={isDisabled}

                        /></td>
                      <td style={tdStyle}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.sollution2}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('sollution2', e.target.value)}
                          disabled={isDisabled}

                        /></td>
                    </tr>
                    <tr>
                    </tr>
                    <tr>
                      <th style={thStyle}>MY DAILY AFFIRMATION</th>
                      <th style={thStyle}>My Passion</th>
                      <th style={thStyle}>My Life Manifesto</th>
                      <th style={thStyle}>Current Life Rating</th>
                      <th style={thStyle}>Potential Solution</th>
                    </tr>
                    <tr>
                      <td style={tdStyle} rowSpan={12}>{renderAffirmations()}</td>
                      <td style={tdStyle} >
                        {getNestedData('categories_data', 'answer', 'My Passion')}
                      </td>
                      <td style={tdStyle} >
                        {rendermylifemanifesto()}
                      </td>
                      <td style={tdStyle} ><textarea
                        style={textAreaStyle}
                        placeholder="Insert Here..."
                        value={formData.ratting3}
                        onChange={(e) => handleFormDataChange('ratting3', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                      <td style={tdStyle}><textarea
                        style={textAreaStyle}
                        value={formData.sollution3}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('sollution3', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>

                    <tr>
                      <th style={thStyle}>MY 2 WORD GOOGLE SEARCH</th>
                      <th style={thStyle}>MY LIFE MOTTO</th>
                      <th style={thStyle} colSpan={2}>
                        1 YEAR LIFE GOALS (Area of Life / Results I Want)
                      </th>
                    </tr>
                    <tr>
                      <td style={tdStyle} rowSpan={2}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.googleSearch}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('googleSearch', e.target.value)}
                          disabled={isDisabled}

                          rows={5}
                        />
                      </td>
                      <td style={tdStyle} rowSpan={2}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.motto}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('motto', e.target.value)}
                          disabled={isDisabled}

                          rows={5}
                        />
                      </td>
                      <td style={tdStyle}>Health/Fitness</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.health}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('health', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Love</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.love}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('love', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <th style={thStyle}>My Super Hero Alter-Ego</th>
                      <th style={thStyle}>My Promise To Myself</th>
                      <td style={tdStyle}>Financial</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.financial}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('financial', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <td style={tdStyle} rowSpan={2}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.alterEgo}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('alterEgo', e.target.value)}
                          disabled={isDisabled}

                          rows={5}
                        />
                      </td>
                      <td style={tdStyle} rowSpan={2}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.promise}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('promise', e.target.value)}
                          disabled={isDisabled}

                          rows={5}
                        />
                      </td>
                      <td style={tdStyle}>Travel</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.travel}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('travel', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Career</td>
                      <td style={tdStyle}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.career}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('career', e.target.value)}
                          disabled={isDisabled}

                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={thStyle}>My Real Life Role Model</th>
                      <th style={thStyle}>My Uniqueness</th>
                      <td style={tdStyle}>Business</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.business}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('business', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <td style={tdStyle} rowSpan={2}>
                        <textarea
                          style={textAreaStyle}

                        />
                      </td>
                      <td style={tdStyle} rowSpan={2}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.uniqueness}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('uniqueness', e.target.value)}
                          disabled={isDisabled}

                          rows={5}
                        />
                      </td>
                      <td style={tdStyle}>Continued Education</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.continuedEducation}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('continuedEducation', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Personal</td>
                      <td style={tdStyle}>  <textarea
                        style={textAreaStyle}
                        value={formData.personal}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('personal', e.target.value)}
                        disabled={isDisabled}

                      /></td>
                    </tr>
                    <tr>
                      <th style={thStyle}>MY Strengths</th>
                      <th style={thStyle}>MY Weaknesses</th>
                      <td style={tdStyle}>House/Home</td>
                      <td style={tdStyle}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.house}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('house', e.target.value)}
                          disabled={isDisabled}

                        />

                      </td>
                    </tr>
                    <tr>
                      <td style={tdStyle}> {rendermystrengths()} </td>
                      <td style={tdStyle}>{rendermyweakness()}
                      </td>
                      <td style={tdStyle}></td>
                      <td style={tdStyle}></td>
                    </tr>
                    <tr>
                      <th style={thStyle}> My Very Big Dream (My North Star)</th>
                      <th style={thStyle}>Top Life Goals I Want From My Big Dream</th>
                      <th style={thStyle} colSpan={2}>
                        The 2-3 Most Important Actions Needed To Achieve My Life Goal
                      </th>
                      <th style={thStyle}>Other Resource Or Support I Will Need Later To Achieve My Life Goal</th>
                    </tr>
                    <tr>
                      <td style={tdStyle}>
                        {rendermyverybigdream()}
                      </td>
                      <td style={tdStyle}>
                        {rendertopfivecorevalues()}
                      </td>
                      <td style={tdStyle} colSpan={2}>
                        <textarea
                          style={textAreaStyle}
                          value={formData.achieveGoals}
                          placeholder="Insert Here..."
                          onChange={(e) => handleFormDataChange('achieveGoals', e.target.value)}
                          disabled={isDisabled}

                        />
                      </td>

                      <td style={tdStyle}><textarea
                        style={textAreaStyle}
                        value={formData.resource}
                        placeholder="Insert Here..."
                        onChange={(e) => handleFormDataChange('resource', e.target.value)}
                        disabled={isDisabled}

                      /> </td>
                    </tr>
                  </tbody>
                </table>            
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyLoader>
  );
};

export default Life360Id;
