import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import MyLifeTheme from "./myLifeTheme";
import MyTaskDefault from "./myTasks";
import DailyAffirmation from "./dailyAfformation";
import DashBoardHabit from "./dashboardHeabit";
import TrackLifeGoals from "./lifeGoals";
import DashboardReport from "./dashboardReport";
import BarChartdashboard from "./lifeplanprogress";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Default = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


  // useEffect(() => {
  //   fetchData(selectedYear);
  // }, [selectedYear]);


  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-xl-12 xl-100">
            <div className="row">
              <EventCharts />
            </div>
          </div> */}
          <div className="col-sm-12">
          <BarChartdashboard/>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <MyLifeTheme />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <MyTaskDefault />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
            <UpcomingAction />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <PriorityTask />
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <DailyAffirmation />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
          
          
              {/* {chartData.datasets[0].data.length > 0 ? (
              <div className="card-body">
                <div className="smooth-chart flot-chart-container">
                
                    <Line data={chartData} options={gradientChartOptions} height="360" />
                 
                </div>
              </div>
               ) : (
                <p className="text-center mt-4">No data to display</p>
              )} */}

              <TrackLifeGoals/>
            
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <DashBoardHabit />
          </div>
          {/* <div className="col-lg-12 col-md-12 col-sm-12">
            <CurrentLifeRatting />
          </div> */}
       

          <div className="col-lg-12 col-md-12 col-sm-12">
            <DashboardReport />
          </div>

          
        </div>
      </div>
      {isDeleteModalOpen && (
        <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete {selectedItem && selectedItem.thoseiwanttoserve}?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
            <button className="btn btn-danger me-1" onClick={toggleDeleteModal}>Delete</button>
          </Modal.Footer>
        </Modal>
      )}

    </Fragment>
  );
};

export default Default;

export var gradientChartOptions = {
  maintainAspectRatio: false,
  height: 360,
  width: 500,
  bezierCurve: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'category',
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
      display: true,
    },
    y: {
      type: 'linear',
      display: true,
    },
  },
};
