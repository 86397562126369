import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';

const DynamicStep = ({ step, questions, formData, handleChange }) => (
    <>
        {questions.filter((_, index) => step === 1 ? index < 4 : index >= 4).map((question) => (
            <div className="form-group row mb-2" key={question.id}>
                <label className="col-sm-12 col-form-label mb-2 p-0">{question.question}</label>
                <textarea
                    className="form-control"
                    name={question.id}
                    value={formData[question.id]}
                    onChange={handleChange}
                    rows="4"
                ></textarea>
            </div>
        ))}
    </>
);

const ValueAndPerpose = () => {
    const [questions, setQuestions] = useState([]);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const uid = localStorage.getItem('user_id') || '';

    const handleSubmitmulti = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const progress = (step / 2) * 100;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "4");
        formDataToSend.append('sub_category_id', "2");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Values & Purpose</h5>
                    </div>
                    <form onSubmit={handleSubmitmulti}>
                        <div className="card-body m-3">
                            <ProgressBar now={progress} label={`Step ${step} of 2`} className="ProgressBar h-25 mb-3" />
                            <DynamicStep step={step} questions={questions} formData={formData} handleChange={handleChange} />
                        </div>
                        <div className="card-footer text-end">
                            {step > 1 && <button type="button" className="btn btn-secondary mx-1" onClick={handlePrevious}>Previous</button>}
                            {step < 2 && <button type="button" className="btn btn-primary mx-1" onClick={handleNext}>Next</button>}
                            {step === 2 && <button type="submit" className="btn btn-success mx-1">Submit</button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ValueAndPerpose;
