import React, { Fragment } from 'react';

import BarChart from '../dashboard/defaultCompo/lifePlanBarChart';

const ChartJs = () => {
  
  return (
    <Fragment>
      {/* <Breadcrumb title="Life 360 Progress" parent="My Reports" /> */}
      <div className="col-sm-12">
      <BarChart/>
      </div>
    </Fragment>
  );
};

export default ChartJs;
