import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API, { isSubscribedAccountant } from '../../../utils';

const TestResultNatural = ({ activeTab }) => {
    const navigate = useNavigate();
    const [visionStatements, setVisionStatements] = useState({});
    const [lifeAreas, setLifeAreas] = useState([]);
    const uid = localStorage.getItem("user_id");
    const [debounceTimer, setDebounceTimer] = useState(null);

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
return;
        }
    }, [navigate]);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_question_to_natural_disc_test_result}`,
                null, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setLifeAreas(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch role model data error', error);
            toast.error("Failed to fetch role model data.");
        }
    };

    const fetchAnswers = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_to_natural_disc_test_result}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const visionData = response.data.data.reduce((acc, item) => {
                    acc[item.question_id] = {
                        rating: item.rating,
                        insert_note: item.insert_note,
                    };
                    return acc;
                }, {});
                setVisionStatements(visionData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch answers data error', error);
            toast.error("Failed to fetch answers data.");
        }
    };

    const handleChange = (e, key, field) => {
        const { value } = e.target;
        setVisionStatements({
            ...visionStatements,
            [key]: {
                ...visionStatements[key],
                [field]: value,
            },
        });
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newTimer = setTimeout(() => {
            handleSubmit(key);
        }, 2000); // Adjust the delay as needed

        setDebounceTimer(newTimer);
    };

    const handleSubmit = useCallback(async (key) => {
        const visionStatement = visionStatements[key];
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id', key);
        formDataToSend.append('rating', visionStatement?.rating);
        formDataToSend.append('insert_note', visionStatement?.insert_note);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_to_natural_disc_test_result}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // fetchAnswers(); // Fetch updated answers
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Add vision statement error', error);
            toast.error("Failed to add vision statement.");
        }
    }, [visionStatements, uid]);

    useEffect(() => {
        fetchData();
        fetchAnswers(); // Fetch answers data on component mount
    }, []);

    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th colSpan={3}>Natural Style</th>
                    {/* <th colSpan={1}>Action</th> */}
                </tr>
            </thead>
            <tbody>
                {lifeAreas.map((area) => (
                    <tr key={area.id}>
                        <td><b>{area.question}</b></td>
                        <td>
                            <select
                                className="form-control vision-select"
                                name={area.id}
                                value={visionStatements[area.id]?.rating || ''}
                                onChange={(e) => handleChange(e, area.id, 'rating')}
                            >
                                <option value="">Select Rating</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>
                        </td>
                        <td>
                            <textarea
                                className="form-control vision-text-area"
                                name={area.id}
                                placeholder="Insert Here..."
                                value={visionStatements[area.id]?.insert_note || ''}
                                onChange={(e) => handleChange(e, area.id, 'insert_note')}
                            />
                        </td>
                        {/* <td>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit(area.id)}
                        >
                            Save
                        </button>
                    </td> */}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TestResultNatural;
