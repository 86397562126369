import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import Determining from "./myLagacyTabs/determining";
import Family from "./myLagacyTabs/family";
import Personal from "./myLagacyTabs/personal";
import Work from "./myLagacyTabs/work";
import Community from "./myLagacyTabs/community";
import Mission from "./myLagacyTabs/mission";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const MyLegacy = () => {
    const navigate = useNavigate()
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("Legacy");

    useEffect(() => {
        fetchTabs();
    }, []);
    
    useEffect(() => {
    if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
return;
      }
    
    }, []);
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };

    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "My Legacy");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.futureSUb}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };


  return (
    <>

<Container fluid={true}>
                <Tabs
                activeKey={activeTab}
                onSelect={handleTabChange}
                    defaultActiveKey="Legacy"
                    id="justify-tab-example"
                    className="border-tab nav-primary mb-3"
                    justify
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                            {getTabContent(tab.sub_category)}
                        </Tab>
                    ))}
                </Tabs>
            </Container>
    </>
  );

}
const getTabContent = (tabName) => {
  switch (tabName) {
      case 'Legacy':
          return <Determining />;
      case 'Personal':
          return <Personal />;
      case 'Family':
          return <Family />;
      case 'Work':
          return <Work />;
      case 'Community':
          return <Community />;
      case 'Mission':
          return <Mission />;
      default:
          return null;
  }
};

export default MyLegacy;
