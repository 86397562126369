import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../utils";
import ReportAccountability from "./accountabilityreport";

const AddAccountabilityPartner = () => {
    const user_id = localStorage.getItem('user_id');
    const isSubscriptionValid = localStorage.getItem("isSubscriptionValid") === 'true'; // Retrieve and check validity
    const [forms, setForms] = useState([]);
    const [errors, setErrors] = useState([]);

    const fetchAccountabilityPartners = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.GetDetailsAbilityPartner}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const mappedForms = response.data.responses.map(partner => ({
                    id: partner.id,         // Ensure `id` is included
                    names: partner.name,
                    emails: partner.email,
                    selectedFrequency: partner.frequencies,

                }));
                setForms(mappedForms);
                setErrors(new Array(mappedForms.length).fill({}));
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch accountability partners');
        }
    };

    useEffect(() => {
        fetchAccountabilityPartners();
    }, [user_id]);

    const handleChange = (index, field, value) => {
        const newForms = [...forms];
        newForms[index][field] = value;
        setForms(newForms);

        const newErrors = [...errors];
        newErrors[index] = { ...newErrors[index], [field]: undefined };
        setErrors(newErrors);
    };

    const addForm = () => {
        setForms([...forms, { id: '', names: '', emails: '', selectedFrequency: 'onDemand' }]); // Add frequency in new form
        setErrors([...errors, {}]);
    };

    const validateForm = (index) => {
        const newErrors = [...errors];
        const form = forms[index];
        const formErrors = {};

        if (form.names.trim() === '') formErrors.names = 'This field is required';
        if (form.emails.trim() === '') {
            formErrors.emails = 'Email is required';
        } else if (!isValidEmail(form.emails)) {
            formErrors.emails = 'Invalid email format';
        }

        newErrors[index] = formErrors;
        setErrors(newErrors);
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleSubmit = async () => {
        const validationErrors = forms.reduce((errorsArray, form, index) => {
            const formErrors = {};
            if (form.names.trim() === '') formErrors.names = 'This field is required';
            if (form.emails.trim() === '') {
                formErrors.emails = 'Email is required';
            } else if (!isValidEmail(form.emails)) {
                formErrors.emails = 'Invalid email format';
            }
            if (Object.keys(formErrors).length > 0) {
                errorsArray[index] = formErrors;
            }
            return errorsArray;
        }, []);

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', user_id);

        forms.forEach((form, index) => {
            formDataToSend.append(`emails[${index}]`, form.emails);
            formDataToSend.append(`names[${index}]`, form.names);
            formDataToSend.append(`frequencies[${index}]`, form.selectedFrequency); // Send frequency as well

        });

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.CreateAccountAbilityPartner}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                fetchAccountabilityPartners();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to submit accountability partners');
        }
    };

    const deleteForm = async (index) => {
        const formToDelete = forms[index];
        if (!formToDelete.id) {
            toast.error('ID is not available for this form');
            return;
        }

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.DeleteAbilityPartner}/${formToDelete.id}`,
                null,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                fetchAccountabilityPartners();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to delete accountability partner');
        }
    };

    const sendReportToPartner = async (email, frequencies) => {
        // Logic for sending the report
        const formDataToSend = new FormData();
        formDataToSend.append('email', email);  // Use the function parameter 'email'
        formDataToSend.append('frequencies', frequencies);  // Use the function parameter 'frequency'

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.sendPdf}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                toast.success('Report sent to Accountability Partner!');
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to send the report');
        }
    };


    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Accountability Partner</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="col-md-12 text-end">
                                {isSubscriptionValid && (
                                    <Button className="mx-2" onClick={addForm}>
                                        {forms.length === 0 ? 'Add Partner' : 'Add More'}
                                    </Button>
                                )}
                            </div>
                            <div className="row">
                                {forms.length > 0 ? forms.map((form, index) => (
                                    <div className="card-body" key={form.id || index}>
                                        <div className="textnots-container-outcoms">
                                            <div className="row">
                                                <div className="text-end">
                                                    {form.id && (
                                                        <Button variant="danger" className="invert-member p-0 m-0" onClick={() => deleteForm(index)}>
                                                            <i className="fa fa-times"></i>
                                                        </Button>
                                                    )}
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label className="form-label"><b>Name</b></label>
                                                        <input
                                                            className={`form-control ${errors[index]?.names ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Enter Your Name"
                                                            value={form.names}
                                                            onChange={(e) => handleChange(index, 'names', e.target.value)}
                                                            onBlur={() => validateForm(index)}
                                                        />
                                                        {errors[index]?.names && <div className="text-danger">{errors[index]?.names}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label className="form-label"><b>Email</b></label>
                                                        <input
                                                            className={`form-control ${errors[index]?.emails ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Enter Your Email"
                                                            value={form.emails}
                                                            onChange={(e) => handleChange(index, 'emails', e.target.value)}
                                                            onBlur={() => validateForm(index)}
                                                        />
                                                        {errors[index]?.emails && <div className="text-danger">{errors[index]?.emails}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label className="form-label"><b>Frequency of Updating Accountability Partner with Emailed Report</b></label>
                                                        <select
                                                            className="form-control"
                                                            value={form.selectedFrequency}
                                                            onChange={(e) => handleChange(index, 'selectedFrequency', e.target.value)}
                                                        >
                                                            <option value="on-demand">On-Demand Only</option>
                                                            <option value="weekly">Weekly</option>
                                                            <option value="monthly">Monthly</option>
                                                            <option value="quarterly">Quarterly</option>
                                                            <option value="annually">Annually</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <p><strong>On-Demand Only:</strong> Will only share your report if you manually press the button.</p>
                                                    <p><strong>Weekly:</strong> Will send a report email every Monday.</p>
                                                    <p><strong>Monthly:</strong> Will send a report email every 1st of the month.</p>
                                                    <p><strong>Quarterly:</strong> Will send a report email every Jan 1st, April 1st, July 1st, and Oct 1st.</p>
                                                    <p><strong>Annually:</strong> Will send a report email every January 1st.</p>
                                                </div>
                                                <div className="text-end mt-4">
                                                    {form.names && form.emails && (
                                                        <Button
                                                            variant="primary"
                                                            className="SendReport"
                                                            onClick={() => sendReportToPartner(form.emails, form.selectedFrequency)} // Pass email and frequency here
                                                        >
                                                            Send Report to Accountability Partner Now
                                                        </Button>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <p className="text-center"><b>No available partners.</b></p>
                                )}
                            </div>
                        </div>
                        {forms.length > 0 && (
                            <div className="card-footer col-md-12 text-end">
                                <Button variant="success" className="mx-2" onClick={handleSubmit} type="button">Save</Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* New Section: Accountability Report */}
            <div className="row mt-4">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Accountability Report</h5>
                        </div>
                        <div className="card-body">
                            <ReportAccountability />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddAccountabilityPartner;
