import React, { useEffect, useState, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce'; // Add this
import API, { isSubscribedAccountant } from '../../utils';

const MyVisionStatement = () => {
    const uid = localStorage.getItem("user_id");
    const [visionStatements, setVisionStatements] = useState({});
    const [lifeAreas, setLifeAreas] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
            return;
        }
    }, [navigate]);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_first_question_answer}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const fetchedLifeAreas = response.data.data;
                setLifeAreas(fetchedLifeAreas);

                const visionData = {};
                fetchedLifeAreas.forEach(area => {
                    if (area.answer) {
                        visionData[area.id] = area.answer;
                    }
                });
                setVisionStatements(visionData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch vision statement error', error);
            toast.error("Failed to fetch vision statements.");
        }
    };

    useEffect(() => { 
        fetchData(); 
    }, []);

    const saveVisionStatement = async (id, value) => {
        try {
            const formData = new FormData();
            
            formData.append('id', id);
            formData.append('answer', value);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_first_question_answer}`, 
                formData, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // toast.success("Vision statement saved successfully!");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Save vision statement error', error);
            toast.error("Failed to save vision statement.");
        }
    };

    // Debounce the API call to avoid excessive requests
    const debouncedSave = useCallback(
        debounce((id, value) => {
            saveVisionStatement(id, value);
        }, 500),
        []
    );

    const handleInputChange = (id, value) => {
        setVisionStatements((prevState) => ({
            ...prevState,
            [id]: value,
        }));

        // Trigger the debounced API call
        debouncedSave(id, value);
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>My Vision Statement</h5>
                            </div>
                            <div className="card-body">
                                <div className="myvision mb-3">
                                    <p className="textnots-text">
                                        Take some time to think about every area of your life. Create a vision statement for each of these areas. This should be something that resonates with you and something that feels good when you think about. Where do you want to see yourself in 5, 10, 15, or 20 years from now in this area of your life? Create that vision now.
                                    </p>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="life-area-head" scope="col">Life Areas</th>
                                            <th scope="col">Vision Statement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lifeAreas.map((area) => (
                                            <tr key={area.id}>
                                                <td className='life-areas'><b>{area.category}</b></td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={visionStatements[area.id] || ''}
                                                        onChange={(e) => handleInputChange(area.id, e.target.value)}
                                                        placeholder="Enter your vision statement"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyVisionStatement;
