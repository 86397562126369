import React, { Fragment, useRef, useState, useEffect } from "react";
import axios from 'axios';
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import ExcelJS from 'exceljs';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 5,
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
  },
  row: {
    margin: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'row',
    minWidth: 40,
    maxWidth: 70,

  },
  cell: {
    margin: 5,
    padding: 5,
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'left',
    fontSize: 8,
    width: 'auto',
    minWidth: 45,
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap', // Prevent text wrapping for long text
  },
});

const PdfDocument = ({ lifeFactors, lifeFactorQuestion, answers }) => (
  <Document>
    <Page size={[869.89, 1530.77]} style={styles.page}>
      <View style={styles.table}>
        <View style={styles.row}>
          <Text style={[styles.cell, { backgroundColor: '#5592dc', color: '#FFFFFF' }]}>Factors / Questions</Text>
          {lifeFactorQuestion.map((question, index) => (
            <Text style={styles.cell} key={index}>{question.question}</Text>
          ))}
        </View>
        {lifeFactors.map((factor) => (
          <View style={styles.row} key={factor.name}>
            <Text style={[styles.cell, { backgroundColor: '#5592dc', color: '#FFFFFF' }]}>{factor.name}</Text>
            {lifeFactorQuestion.map((question) => {
              const matchedAnswer = answers.find(a => a.category === factor.name && a.question === question.question);
              const answerValue = matchedAnswer ? matchedAnswer.answer : "N/A";
              return <Text style={[styles.cell, { backgroundColor: '#5592dc', color: '#FFFFFF' }]} key={question.question} >{answerValue}</Text>;
            })}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);




const Life360Bluprint = () => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("user_id");
  const [lifeFactors, setLifeFactors] = useState([]);
  const [lifeFactorQuestion, setLifeFactorQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const componentRef = useRef();

  const handleDownloadCsv = async () => {
    if (lifeFactors.length === 0 || lifeFactorQuestion.length === 0 || answers.length === 0) {
      console.error("No data available to generate Excel.");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Life Action Plan');

    const headerRow = worksheet.addRow(["Questions / Factors", ...lifeFactors.map(factor => factor.name)]);

    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' },
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    const getColorForPercentage = (percentage) => {
      if (percentage === 0) return "FFFF0000";
      if (percentage === 25) return "FFFFA500";
      if (percentage === 50) return "FFFFFF00";
      if (percentage === 75) return "FF90EE90";
      if (percentage === 100) return "FF008000";
      return "FFFFFFFF";
    };

    const getColorForRating = (rating) => {
      if (rating >= 0 && rating <= 3) return "FFFF0000";
      if (rating === 4) return "FFFFA500";
      if (rating >= 5 && rating <= 7) return "FFFFFF00";
      if (rating >= 8 && rating <= 10) return "FF008000";
      return "FFFFFFFF";
    };

    lifeFactorQuestion.forEach((question, rowIndex) => {
      const row = worksheet.addRow([
        question.question,
        ...lifeFactors.map(factor => {
          const matchedAnswer = answers.find(a => a.category === factor.name && a.question === question.question);
          return matchedAnswer ? matchedAnswer.answer : "N/A";
        })
      ]);

      row.eachCell((cell, cellIndex) => {
        const isEvenRow = rowIndex % 2 === 0;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: isEvenRow ? 'FFDAEEF3' : 'FFDCE6F1' },
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      // Apply conditional coloring for percentage and rating questions
      lifeFactors.forEach((factor, factorIndex) => {
        const cell = row.getCell(factorIndex + 2);
        const matchedAnswer = answers.find(a => a.category === factor.name && a.question === question.question);
        let bgColor = "FFFFFFFF"; // Default white

        if (question.question.includes("Percentage Complete") && matchedAnswer) {
          const percentage = parseFloat(matchedAnswer.answer);
          bgColor = getColorForPercentage(percentage);
        } else if (question.question.includes("Current Overall Rating") && matchedAnswer) {
          const rating = parseInt(matchedAnswer.answer, 10);
          bgColor = getColorForRating(rating);
        }

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: bgColor },
        };
      });
    });

    // Export Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'My_Personal_Operating_System_Life_Action_Plan.xlsx';
    link.click();
  };

  const verticalRow = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.vertialrow}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        }
      });
      const data = response.data;
      if (data.status === "success") {
        setLifeFactorQuestion(data.life_area_vertical);
      } else {
        console.error("Failed to fetch vertical rows");
      }
    } catch (error) {
      console.error("Error fetching vertical rows:", error.message);
    }
  };

  const horizontalRow = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid); // Ensure 'uid' is defined

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.all_life_area}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          }
        });

      const data = response.data;
      if (data.status === "success") {
        setLifeFactors(data.life_area); // Set life factors in state
      } else {
        console.error("Failed to fetch horizontal rows:", data.message);
      }
    } catch (error) {
      console.error("Error fetching horizontal rows:", error.message);
    }
  };


  const fetchAnswers = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_for_Life360_blueprint}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setAnswers(response.data.data);
      } else {
        console.error("Failed to fetch answers.");
      }
    } catch (error) {
      console.error("Error fetching answers:", error.message);
    }
  };

  useEffect(() => {
    horizontalRow();
    verticalRow();
    fetchAnswers();
  }, []);

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
      return;
    }
  }, [navigate]);





  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-12 align-items-center justify-content-between d-flex">
                    <h5>This Year’s Strategic Plan</h5>

                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Download This Year’s Strategic Plan
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="lifeid p-1">
                        <Dropdown.Item as="button" className="my-2 btn btn-success">
                          <PDFDownloadLink
                            className="text-white"
                            document={<PdfDocument lifeFactors={lifeFactors} lifeFactorQuestion={lifeFactorQuestion} answers={answers} />}
                            fileName="My_Personal_Operating_System_Life_Action_Plan.pdf"
                          >
                            {({ loading }) => (loading ? 'Loading document...' : 'Download Pdf')}
                          </PDFDownloadLink>
                        </Dropdown.Item>

                        <Dropdown.Item as="button" className=" my-2 btn btn-success" onClick={handleDownloadCsv}>
                          Download Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="life-planing-div">
                  <div ref={componentRef}>
                    {lifeFactors.length > 0 && answers.length > 0 ? (
                      <table className="life-planing-table table table-bordered mt-4" >
                        <thead className="d-flex">
                          <tr className="d-flex h-auto align-content-center">
                            <th className="question-anster-th-life360 align-content-center column-width" >
                              Questions / Factors
                            </th>
                            {lifeFactors.map((factor, index) => (
                              <th className="question-anster-th-life360 align-content-center column-width" key={index} >
                                {factor.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {lifeFactorQuestion.map((question, rowIndex) => {
                            const isPercentageRow = question.question.includes("Percentage Complete Against This Years Goals.");
                            const isRatingRow = question.question.includes("Current Overall Rating");

                            const rowBgColor = rowIndex % 2 === 0 ? "#DAEEF3" : "#DCE6F1";

                            return (
                              <tr key={rowIndex} className="lifeprint-tr d-flex h-auto align-content-center" style={{ backgroundColor: rowBgColor,  }}>
                                <td className="text-center life-plan-question-blueprint life-plan-table-width" >
                                  {question.question}
                                </td>
                                {lifeFactors.map((factor, factorIndex) => {
                                  const matchedAnswer = answers.find(
                                    (a) => a.category === factor.name && a.question === question.question
                                  );
                                  let answerValue = matchedAnswer ? matchedAnswer.answer : "N/A";
                                  let bgColor = "transparent";
                                  let customStyles = {}; // To hold dynamic styles

                                  if (isPercentageRow) {
                                    const percentage = parseFloat(answerValue);

                                    if (!isNaN(percentage) && percentage >= 0) {
                                      answerValue = `${percentage}%`;

                                      if (percentage === 0) bgColor = "red";
                                      else if (percentage === 25) bgColor = "orange";
                                      else if (percentage === 50) bgColor = "yellow";
                                      else if (percentage === 75) bgColor = "lightgreen";
                                      else if (percentage === 100) bgColor = "green";
                                    } else {
                                      answerValue = "0%";
                                    }
                                  }

                                  if (isRatingRow) {
                                    const rating = parseInt(answerValue, 10);

                                    if (!isNaN(rating) && rating >= 0) {
                                      if (rating >= 0 && rating <= 3) bgColor = "red";
                                      else if (rating === 4) bgColor = "orange";
                                      else if (rating >= 5 && rating <= 7) bgColor = "yellow";
                                      else if (rating >= 8 && rating <= 10) bgColor = "green";
                                    } else {
                                      answerValue = "0";
                                    }
                                  }

                                  if (isRatingRow) {
                                    customStyles = { fontWeight: 'bold', fontSize: '1.2rem' }; // Bold text and larger font size
                                  }

                                  return (
                                    <td
                                    style={{ backgroundColor: bgColor, , ...customStyles }}
                                    key={factorIndex}
                                      className="text-center column-width"
                                    >
                                      {answerValue}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })} */}
                          {lifeFactorQuestion.map((question, rowIndex) => {
                            const isPercentageRow = question.question.includes("Percentage Complete Against This Years Goals");
                            const isRatingRow = question.question.includes("Current Overall Rating");

                            const rowBgColor = rowIndex % 2 === 0 ? "#DAEEF3" : "#DCE6F1";

                            return (
                              <tr key={rowIndex} className="lifeprint-tr d-flex h-auto align-content-center" style={{ backgroundColor: rowBgColor,  }}>
                                <td className="text-center life-plan-question-blueprint life-plan-table-width" >
                                  {question.question}
                                </td>
                                {lifeFactors.map((factor, factorIndex) => {
                                  const matchedAnswer = answers.find(
                                    (a) => a.category === factor.name && a.question === question.question
                                  );
                                  let answerValue = matchedAnswer ? matchedAnswer.answer : "N/A";
                                  let bgColor = "transparent";
                                  let customStyles = {}; // To hold dynamic styles

                                  // Apply conditional background color and percentage text for percentage rows
                                  if (isPercentageRow) {
                                    const percentage = parseFloat(answerValue);

                                    if (!isNaN(percentage) && percentage >= 0) {
                                      answerValue = `${percentage}%`;

                                      if (percentage === 0) bgColor = "red";
                                      else if (percentage === 25) bgColor = "orange";
                                      else if (percentage === 50) bgColor = "yellow";
                                      else if (percentage === 75) bgColor = "lightgreen";
                                      else if (percentage === 100) bgColor = "green";
                                    } else {
                                      answerValue = "0%";
                                    }

                                    // Apply bold text and larger font size for percentage cells
                                    customStyles = { fontWeight: 'bold', fontSize: '1.2rem' };
                                  }

                                  if (isRatingRow) {
                                    const rating = parseInt(answerValue, 10);

                                    if (!isNaN(rating) && rating >= 0) {
                                      if (rating >= 0 && rating <= 3) bgColor = "red";
                                      else if (rating === 4) bgColor = "orange";
                                      else if (rating >= 5 && rating <= 7) bgColor = "yellow";
                                      else if (rating >= 8 && rating <= 10) bgColor = "green";
                                    } else {
                                      answerValue = "0";
                                    }

                                    // Apply bold and larger font for rating cells
                                    customStyles = { fontWeight: 'bold', fontSize: '1.2rem' };
                                  }

                                  return (
                                    <td
                                      style={{ backgroundColor: bgColor, ...customStyles }}
                                      key={factorIndex}
                                      className="text-center column-width"
                                    >
                                      {answerValue}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}

                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center pt-4">No data available to display.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Life360Bluprint;
