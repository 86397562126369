import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import API, { isSubscribedAccountant } from "../../utils";
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import ViewModal from "./gratitudeModals/viewModals";
import EditModal from "./gratitudeModals/editModals";
import DeleteModal from "./gratitudeModals/deleteModals";
import GratitudeEntrymodal from "./gratitudeModals/gratitudeEntrymodal";

const DailyGratitude = () => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("user_id");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({ gratitude: ["", "", ""] });
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isGratitudeEntryModalOpen, setIsGratitudeEntryModalOpen] = useState(false);
  const [gratitudeEntries, setgratitudeEntries] = useState([]);

  const checkIfSubmitDisabled = () => {
    const allEmpty = formData.gratitude.every(gratitude => gratitude.trim() === "");
    setIsSubmitDisabled(allEmpty);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.gratitudes}/${uid}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const sortedTasks = response.data.gratitudes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setgratitudeEntries(sortedTasks);

        // Check if the user has submitted gratitude for the current day
        const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
        const hasGratitudeForToday = sortedTasks.some(entry => entry.created_at.startsWith(today));

        // Show the modal if no entry exists for today
        if (!hasGratitudeForToday) {
          setIsGratitudeEntryModalOpen(true);
        }
      }
    } catch (error) {
      console.error('Fetch gratitude error', error);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.gratitudes}/${uid}`, {
  //       headers: {
  //         Authorization: `${API.AUTHORIZATION_TOKEN}`,
  //       },
  //     });

  //     if (response.data.status === "success") {

  //       // setgratitudeEntries(response.data.gratitudes);
  //       const sortedTasks = response.data.gratitudes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //       setgratitudeEntries(sortedTasks);

  //     } else {
  //       // toast.error(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Fetch gratitude error', error);
  //     // toast.error("Failed to fetch gratitudes.");
  //   }
  // };

  useEffect(() => {
    fetchData();

    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
      return;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formData.gratitude.forEach((gratitude, index) => {
      formDataToSend.append(`gratitude[${index}]`, gratitude);
    });
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.gratitudes}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setFormData({ gratitude: ["", "", ""] });
        fetchData();
        setIsGratitudeEntryModalOpen(false);
        window.location.reload(); 
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('Add gratitude error', error);
      // toast.error("Failed to add gratitude.");
    }
  }

  const handleInputChange = (e, index) => {
    if (index !== undefined) {
      const { value } = e.target;
      const updatedGratitude = [...formData.gratitude];
      updatedGratitude[index] = value;
      setFormData({ ...formData, gratitude: updatedGratitude });
      checkIfSubmitDisabled(); // Call here
    } else {
      setFormData({ ...formData, date: e });
    }
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    toggleDeleteModal();
  };

  const handleDeleteItem = async () => {
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.deleteGratitudes}/${selectedItem.id}`,
        null,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
        setIsDeleteModalOpen(false);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Delete gratitude error", error);
      // toast.error("Failed to delete gratitude.");
    }
  };

  const toggleEditModal = () => {
    if (selectedItem) {
      setIsEditModalOpen(!isEditModalOpen);
      setFormData({
        date: new Date(selectedItem.date),
        gratitude: [
          selectedItem.gratitude[0],
          selectedItem.gratitude[1],
          selectedItem.gratitude[2],
        ],
      });
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    toggleEditModal();
    setFormData({
      gratitude: [
        item.gratitude[0],
        item.gratitude[1],
        item.gratitude[2],
      ],
    });
  };

  const handleEditItem = async () => {
    const formDataToSend = new FormData();
    formData.gratitude.forEach((gratitude, index) => {
      formDataToSend.append(`gratitude[${index}]`, gratitude);
    });
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.gratitudes}/${selectedItem.id}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        const updatedEntries = gratitudeEntries.map(entry => {
          if (entry.id === selectedItem.id) {
            return {
              ...entry,
              gratitude: [
                formData.gratitude[0],
                formData.gratitude[1],
                formData.gratitude[2],
              ],
            };
          }
          return entry;
        });
        setgratitudeEntries(updatedEntries);
        setIsEditModalOpen(false);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Edit gratitude error', error);
      // toast.error("Failed to edit gratitude.");
    }
  }

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  const handleView = async (item) => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.viewgratitude}/${item.id}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setSelectedItem(response.data.gratitude);
        toggleViewModal();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('DailyGratitude view error', error);
      // toast.error("Failed to fetch Daily Gratitude details.");
    }
  };

  const columns = [
    {
      name: 'Date',
      selector: (row) => format(new Date(row.created_at), 'eeee, MMMM d, yyyy'),
      sortable: true,
      width: "20%"
    },
    {
      name: 'Daily Gratitude',
      cell: (row) => (
        <div>
          <div className="my-1">1. {row.gratitude[0]}</div>
          <div className="my-1">2. {row.gratitude[1]}</div>
          <div className="my-1">3. {row.gratitude[2]}</div>
        </div>
      ),
      sortable: true,
      width: "60%"
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)}><FaEye /></Button>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button className="btn-danger btn-pill main-delete-buttons mx-1" onClick={() => handleDelete(row)}><MdDelete /></Button>
        </>
      ),
      width: "20%"
    },
  ];



  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredgratitudeEntries = (gratitudeEntries || []).filter((entry) =>
    entry.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.gratitude.some((gratitude) => gratitude.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleKeyDownEdit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleEditItem();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();

      if (currentHour === 20 && currentMinute === 0) { // Check if it's 8 PM
        // Send Email Notification
        sendEmailNotification();
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const sendEmailNotification = async () => {
    try {
      await axios.post(`${API.BASE_URL}${API.ENDPOINTS.sendEmailNotification}`, {
        user_id: uid,
      }, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
    } catch (error) {
      console.error("Error sending email notification", error);
    }
  };



  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className=" align-items-center justify-content-between d-flex">
                  <h5>Daily Gratitude</h5>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div>
                <h6>Three Things I’m Grateful for Today</h6>
                <form className="theme-form my-3" onSubmit={handleSubmit}>
                  <div className="row">
                    {formData.gratitude.map((gratitude, index) => (
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group" key={index}>
                          <label htmlFor={`gratitude${index + 1}`}><b>Gratitude {index + 1}</b></label>
                          <textarea
                            id={`gratitude${index + 1}`}
                            placeholder={`Enter Gratitude ${index + 1}`}
                            onChange={(e) => handleInputChange(e, index)}
                            className={`form-control`}
                            // value={formData.gratitude[index]}
                          />

                        </div>
                      </div>
                    ))}
                    <div className="d-flex justify-content-end align-content-center">
                      <button className="btn btn-secondary" type="submit" disabled={isSubmitDisabled} >Save</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="form-group text-end mb-3 ">
                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                  </div>
                </div>
              </div>
              {gratitudeEntries ? (
                <DataTable
                  columns={columns}
                  data={filteredgratitudeEntries}
                  pagination
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          handleDeleteItem={handleDeleteItem}
        />
      )}
      {isEditModalOpen && (
        <EditModal
          isEditModalOpen={isEditModalOpen}
          toggleEditModal={toggleEditModal}
          formData={formData}
          handleInputChange={handleInputChange}
          handleEditItem={handleEditItem}
          handleKeyDownEdit={handleKeyDownEdit}
        />
      )}
      {isViewModalOpen && (
        <ViewModal
          isViewModalOpen={isViewModalOpen}
          toggleViewModal={toggleViewModal}
          selectedItem={selectedItem}
        />
      )}

      {isGratitudeEntryModalOpen && (
        <GratitudeEntrymodal
          isModalOpen={isGratitudeEntryModalOpen}
          toggleModal={() => setIsGratitudeEntryModalOpen(false)}
          handleSubmit={handleSubmit}
          formData={formData} // Ensure formData is defined in DailyGratitude
          handleInputChange={handleInputChange} // Ensure handleInputChange is defined in DailyGratitude
          isSubmitDisabled={isSubmitDisabled} // Ensure isSubmitDisabled is defined in DailyGratitude
        />
      )}
    </div>
  );
};

export default DailyGratitude;
