import React, { useEffect, useState, useCallback } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const RoleModel = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([{ id: null, love: '', hate: '', removethetophated: '' }]);
  const [visionStatements, setVisionStatements] = useState({});
  const [lifeAreas, setLifeAreas] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
return;
    }
  }, [navigate]);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_role_model_data}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const lifeAreasData = response.data.data;
        setLifeAreas(lifeAreasData);

        const initialVisionStatements = {};
        lifeAreasData.forEach(area => {
          if (area.data && area.data.length > 0) {
            const visionData = area.data[0];
            initialVisionStatements[area.category_id] = {
              person: visionData.name_of_person,
              why: visionData.why,
              thing_think: visionData.thing_think,
              action_to_learning: visionData.action_to_learning,
            };
          } else {
            initialVisionStatements[area.category_id] = {
              person: '',
              why: '',
              thing_think: '',
              action_to_learning: '',
            };
          }
        });
        setVisionStatements(initialVisionStatements);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch role model data error', error);
      // toast.error("Failed to fetch role model data.");
    }
  };

  const handleSubmit = useCallback(async (key) => {
    const visionStatement = visionStatements[key];
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('category_id', key);
    formDataToSend.append('name_of_Person', visionStatement?.person || '');
    formDataToSend.append('why', visionStatement?.why || '');

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_role_model}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Add vision statement error', error);
      // toast.error("Failed to add vision statement.");
    }
  }, [visionStatements, uid]);

  const handleSavePeople = useCallback(async () => {
    const formDataToSend = new FormData();
    fields.forEach((field, index) => {
      formDataToSend.append(`name[${index + 1}]`, field.love);
      formDataToSend.append(`think_can_i_learn[${index + 1}]`, field.hate);
      formDataToSend.append(`action[${index + 1}]`, field.removethetophated);
      formDataToSend.append(`id[${index + 1}]`, field.id);
    });

    formDataToSend.append('user_id', uid);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_updated_think_i_can_learn}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchPeopleData();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Add vision statement error', error);
      // toast.error("Failed to add vision statement.");
    }
  }, [fields, uid]);

  const handleChange = (e, key, field) => {
    const { value } = e.target;
    setVisionStatements({
      ...visionStatements,
      [key]: {
        ...visionStatements[key],
        [field]: value,
      },
    });

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      handleSubmit(key);
    }, 2000); // Adjust the delay as needed

    setDebounceTimer(newTimer);
  };

  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      handleSavePeople();
    }, 2000); // Adjust the delay as needed

    setDebounceTimer(newTimer);
  };

  const handleAddRow = () => {
    setFields([...fields, { id: null, love: '', hate: '', removethetophated: '' }]);
  };

  const fetchPeopleData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_from_think_i_can_learn}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const peopleData = response.data.data;

        if (peopleData && peopleData.length > 0) {
          setFields(peopleData.map(person => ({
            id: person.id || '',
            love: person.name || '',
            hate: person.think_can_i_learn || '',
            removethetophated: person.action || ''
          })));
        } else {
          setFields([{ id: null, love: '', hate: '', removethetophated: '' }]);
        }
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch people data error', error);
      // toast.error("Failed to fetch people data.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchPeopleData();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Role Models </h5>
              </div>
              <div className="card-body">
                <div className="myvision mb-3">
                  <p className="textnots-text">
                    Take some time to think about every area of your life. Create a vision statement for each of these areas. This should be something that resonates with you and something that feels good when you think about. Where do you want to see yourself in 5, 10, 15, or 20 years from now in this area of your life? Create that vision now.
                  </p>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" style={{width:"20%"}}>Life Area</th>
                      <th scope="col" style={{width:"30%"}}>Name of Person of Admiration</th>
                      <th scope="col" style={{width:"50%"}}>Why?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lifeAreas.map((area) => (
                      <tr key={area.category_id}>
                        <td><b>{area.category_name}</b></td>
                        <td>
                          <textarea
                            className="form-control vision-text-area"
                            name={area.category_id}
                            placeholder='Insert Here...'
                            value={visionStatements[area.category_id]?.person || ''}
                            onChange={(e) => handleChange(e, area.category_id, 'person')}
                          />
                        </td>
                        <td>
                          <textarea
                            className="form-control vision-text-area"
                            name={area.category_id}
                            placeholder='Insert Here...'
                            value={visionStatements[area.category_id]?.why || ''}
                            onChange={(e) => handleChange(e, area.category_id, 'why')}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="myvision mb-3 mt-3">
                  <h5 className="mb-3">People you want to surround yourself with</h5>
                  <p className="textnots-text">
                    Are there people you want to connect with or surround yourself with who you think can help you accomplish your goal? Or, are there people you want to learn more from? List at least 3 of them here and the actions you can take to connect with them.
                  </p>
 </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Thing I Think I Can Learn</th>
                        <th>Action to Initiate this Learning</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => (
                        <tr key={index}>
                          <td>
                            <div className="form-group">
                               <input
                              type='text'
                                name="love"
                                placeholder="Insert Here..."
                                className="form-control"
                                value={field.love}
                                onChange={event => handleInputChange(index, event)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                                <input
                              type='text'
                                name="hate"
                                placeholder="Insert Here..."
                                className="form-control"
                                value={field.hate}
                                onChange={event => handleInputChange(index, event)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                                <input
                              type='text'
                                name="removethetophated"
                                placeholder="Insert Here..."
                                className="form-control"
                                value={field.removethetophated}
                                onChange={event => handleInputChange(index, event)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                
                  <button
                    className="mt-3 float-end btn btn-success"
                    onClick={handleAddRow}
                  >
                    Add Row
                  </button>
               
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
}

export default RoleModel;
