import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const FreshStory = () => {
    const uid = localStorage.getItem('user_id') || '';
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();

        const handleClearValidationErrors = () => {
            setErrors({});
        };

        window.addEventListener('clearValidationErrors', handleClearValidationErrors);

        return () => {
            window.removeEventListener('clearValidationErrors', handleClearValidationErrors);
        };
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '9');
        formDataToSend.append('sub_category_id', '24');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };


    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[${index + 1}]', questionId);
            formDataToSend.append('answer[${index + 1}]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    fetchQuestions();
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        debouncedUpdateAnswer(name, value);
    };


    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Nurture Your Story</h5>
                    </div>
                    <form className="form theme-form" noValidate>
                        <div className="card-body m-3">
                            <div className="textnots-container-persomality mb-3">
                                <p className="textnots-text">
                                    It’s an understatement to say that your brand is constantly evolving.  You have the opportunity totransform beyond your current persona and become a better, bolder version of yourself.  We’d like toencourage you to push past your comfort zone and be an even better you.   Become a lifelong learner;stay informed and inspired.  Be resilient, passionate, respectful, and committed.  In this final section ofour workbook we want you to keep moving forward.  We encourage you to stay motivated, dig deeperinto your skills set and to especially monitor yourself to determine if you need to make a change.   Hereare our last ideas to share for your personal brand.                                </p>
                            </div>
                            <div className="row mb-2 mx-1">
                                <div className="col-sm-12">
                                    {questions.map((question) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <label className="col-sm-12 col-form-label  mb-2 p-0"><b>{question.question}</b></label>
                                            <textarea
                                                className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                                                name={question.id}
                                                value={formData[question.id] || ''}
                                                onChange={handleChange}
                                                rows="4"

                                            ></textarea>
                                            {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                                        </div>
                                    ))}


                                </div>
                            </div>
                        </div>
                      
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FreshStory;
