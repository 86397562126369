import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaTasks } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";

const MyTaskDefault = () => {
  const uid = localStorage.getItem("user_id");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [checkedTasks, setCheckedTasks] = useState({});

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.inProcessTask}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === "success") {
        setTasks(response.data.data || []);
      } else {
        toast.error(response.data.message || "Failed to fetch tasks");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      // toast.error('Failed to fetch tasks. Please try again later.');
    }
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.deleteTask}/${selectedItem.id}`,
        null,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchTasks();
        setIsDeleteModalOpen(false);
      } else {
        // toast.error(response.data.message || "Failed to delete task.");
      }
    } catch (error) {
      console.error("Task app delete error", error);
    }
  };

  const handleCheckboxChange = async (taskId) => {
    const updatedCheckedTasks = {
      ...checkedTasks,
      [taskId]: !checkedTasks[taskId],
    };
    setCheckedTasks(updatedCheckedTasks);

    const formDataToSend = new FormData();
    formDataToSend.append("id", taskId);
    formDataToSend.append("is_checked", updatedCheckedTasks[taskId] ? 1 : 0);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.update_dashboard_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            "Content-Type": "multipart/form-data", // Ensure the content type is set to multipart/form-data
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchTasks();
      } else {
        toast.error(response.data.message || "Failed to update task status.");
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      toast.error("Failed to update task status. Please try again later.");
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="without-btn">
            <FaTasks /> My Tasks
          </h5>
        </div>
        <div className="card-body">
          <div className="activity">
            {tasks && tasks.length > 0 ? (
              tasks.slice(0, 4).map((task, index) => (
                <div className="d-flex" key={index}>
                  <div className="mb-3">
                    <input
                      type="checkbox"
                      className="checkbox_animated"
                      checked={!!checkedTasks[task.id]}
                      onChange={() => handleCheckboxChange(task.id)}
                    />
                  </div>
                  {/* <div className="activite-label">{format(new Date(task.updated_at), 'yyyy-MM-dd')}</div> */}
                  <div className="flex-shrink-0 gradient-round NewMessage m-r-30 gradient-line-1"></div>
                  <div className="flex-grow-1 task-titles d-flex justify-content-between">
                    <h6 className="ml-2">{task.title}</h6>
                    {/* <div className="task-svg">
                      <Button className="btn-danger btn-pill main-delete-buttons" onClick={() => handleDelete(task)}>
                        <MdDelete />
                      </Button>
                    </div> */}
                  </div>
                </div>
              ))
            ) : (
              <p>No tasks available.</p>
            )}
          </div>
        </div>
      </div>

      <Modal show={isDeleteModalOpen} onHide={toggleDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the task "{selectedItem?.title}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleDeleteModal}>
            Close
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyTaskDefault;
