import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from 'date-fns';
import ViewModal from "./ThoseIWantToServemodal/viewThoseIWantToServe";
import EditModal from "./ThoseIWantToServemodal/editThoseIWantToServe";
import DeleteModal from "./ThoseIWantToServemodal/deleteThoseIWantToServe";
import AddModal from "./ThoseIWantToServemodal/addThoseIWantToServe";
import API, { isSubscribedAccountant } from "../../utils";
import { useNavigate } from "react-router-dom";

const ThoseIWantToServe = () => {
  const uid = localStorage.getItem("user_id");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editedItemError, setEditedItemError] = useState('');
  const [core, setCore] = useState("");
  const [coreBorderError, setCoreBorderError] = useState(false);
  const [coreerror, setCoreerror] = useState("");
  const [serve, setServe] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
return;
    }
  }, []);
  const handleAdd = async () => {
    let isError = false;
    if (!core) {
      setCoreerror("This field is required");
      setCoreBorderError(true);
      isError = true;
    } else {
      setCoreerror("");
      setCoreBorderError(false);
    }
    if (isError) {
      return;
    } else {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('text', core);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.add_those_serve}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        );

        if (response.data.status === "success") {
          // toast.success(response.data.message);
          togglePopup();
          fetchthoseiwanttoserve();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error during login:", error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    fetchthoseiwanttoserve();
  }, []);

  const fetchthoseiwanttoserve = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.all_those_serve}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.status === "success") {
        setServe(response.data.all_those_serve);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const togglePopup = () => {
    setCore("");
    setCoreerror("");
    setCoreBorderError(false);
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    toggleDeleteModal();
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setEditedItem(item.text || '');
    toggleEditModal();
  };

  const handleDeleteItem = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('those_serve_id', selectedItem.id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.delete_those_serve}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        setIsDeleteModalOpen(false);
        fetchthoseiwanttoserve();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Delete key Relationship error", error);
      toast.error("Failed to delete key Relationship.");
    }
  };

  const handleEditItem = async () => {
    if (!editedItem.trim()) {
      setEditedItemError('This field is required.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('text', editedItem);
    formDataToSend.append('those_serve_id', selectedItem.id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.update_those_serve}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchthoseiwanttoserve();
        toggleEditModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during update:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = serve.filter(entry =>
    entry.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  const columns = [
    {
      name: 'Sr No.',
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: 'Date',
      selector: (row) => format(new Date(row.created_at), 'yyyy-MM-dd'),
      sortable: true,
    },
    {
      name: 'Those I Want to Serve',
      selector: (row) => row.text,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)}><FaEye /></Button>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button variant="danger" className=" btn-pill main-buttons mx-1" onClick={() => handleDelete(row)}>
            <MdDelete />
          </Button>
        </>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header ">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Those I Want to Serve</h5>
                    <Button className="btn-success main-buttons" onClick={togglePopup}>Add  Those I Want To Serve</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card-body ">
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="form-group text-end mb-3 ">
                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredEntries}
                pagination
                className="text-center"
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
      </div>

      <AddModal
        isOpen={isPopupOpen}
        toggle={togglePopup}
        handleAdd={handleAdd}
        core={core}
        setCore={setCore}
        coreerror={coreerror}
        setCoreerror={setCoreerror}
        coreBorderError={coreBorderError}
        setCoreBorderError={setCoreBorderError}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        handleDeleteItem={handleDeleteItem}
        selectedItem={selectedItem}
      />
      <EditModal
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        handleEditItem={handleEditItem}
        editedItem={editedItem}
        setEditedItem={setEditedItem}
        editedItemError={editedItemError}
        setEditedItemError={setEditedItemError}
      />
      <ViewModal
        isOpen={isViewModalOpen}
        toggle={toggleViewModal}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default ThoseIWantToServe;