import React, { useEffect, useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";


const SpecificQuestions = () => {
  const uid = localStorage.getItem('user_id') || '';
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchQuestions();
  }, []);
  useEffect(() => {
    fetchQuestions();

    const clearErrors = () => setErrors({});
    window.addEventListener('clearValidationErrors', clearErrors);

    return () => {
        window.removeEventListener('clearValidationErrors', clearErrors);
    };
}, []);
  const fetchQuestions = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('main_category_id', '1');
    formDataToSend.append('sub_category_id', '2');

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getquestionbymainandsub}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === 'success') {
        setQuestions(response.data.question);
        const initialFormData = {};
        (response.data.question || []).forEach((question) => {
          initialFormData[question.id] = question.answer || '';
        });
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message || 'Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast.error('Failed to fetch questions. Please try again later.');
    }
  };

  const handleSubmitwork = (e) => {

    e.preventDefault();

    const validationErrors = {};
    questions.forEach((question) => {
      if ((formData[question.id] || '').trim() === '') {
        validationErrors[question.id] = 'This field is required';
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setValidated(true);
    handleSubmit();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    questions.forEach((question, index) => {
      formDataToSend.append(`question_id[${index + 1}]`, question.id);
      formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
    });

    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.addupdateanswermybelief}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        fetchQuestions();
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || 'Failed to update answers');
      }
    } catch (error) {
      console.error('Error updating answers:', error);
      toast.error('Failed to update answers. Please try again later.');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Specific Question
              </h5>
            </div>
            <form className="form theme-form" noValidate validated={validated} onSubmit={handleSubmitwork}>
             <div className="card-body m-3">
                <div className="row">
                  <div className="col-sm-12">
                    {questions.map((question) => (
                      <div className="form-group row mb-2" key={question.id}>
                        <label className="col-sm-12 col-form-label  mb-2 p-0">{question.question}</label>
                        <textarea
                          className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                          name={question.id}
                          value={formData[question.id] || ''}
                          onChange={handleChange}
                          rows="4"
                        ></textarea>
                        {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                      </div>
                    ))}


                  </div>
                </div>
              </div>

              <div className="card-footer">
                <button type="submit" className="btn btn-success main-buttons my-3 float-end">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

}

export default SpecificQuestions;
