import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/user/user_default.jpg';
import {useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../../../utils';
import axios from 'axios';

const UserPanel = () => {
    const navigate = useNavigate();
    const user_id = localStorage.getItem("user_id");
    const profileURL = localStorage.getItem('profileURL');
    const user_name = localStorage.getItem('user_name');
    const [formData, setFormData] = useState({
        profile: null,
        profileURL: profileURL || man,
    });

    const clearLocalStorageAndRedirect = () => {
        localStorage.clear(); 
        navigate(`/login`);
    };

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", user_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getuserdetails}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const userData = response.data.user_data;

                if (!userData || userData.length === 0) {
                    toast.error("Data not found. Redirecting to login.");
                    clearLocalStorageAndRedirect();
                    return;
                }

                setFormData({
                    profile: null,
                    profileURL: userData.profile || profileURL || man,
                });
            } else {
                toast.error(response.data.message);
                clearLocalStorageAndRedirect();
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
            toast.error("Something went wrong. Please try again.");
            clearLocalStorageAndRedirect();
        }
    };

    useEffect(() => {
        fetchData();
    }, [user_id]);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div className="d-flex justify-content-between">
                    <div className="img-user">
                        <img
                            className="img-45 rounded-circle lazyloaded blur-up"
                            alt=""
                            src={
                                formData.profile
                                    ? URL.createObjectURL(formData.profile)
                                    : formData.profileURL
                            }
                        />
                    </div>
                    {/* <div className="profile-edit"> */}
                        {/* <Link to={'/users/userEdit'}>
                            <span>Edit</span>
                        </Link> */}
                    {/* </div> */}
                    <div>
                        <h6 className="m-2">{user_name}</h6>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserPanel;
