import React, { useCallback, useEffect, useState } from "react";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { debounce } from "lodash";

const RelationshipWins = () => {
  const navigate = useNavigate()
  const [errors, setErrors] = useState({});
  const [questions, setQuestions] = useState([]);
  const uid = localStorage.getItem('user_id') || '';
  const [formData, setFormData] = useState({});

  useEffect(() => {
      
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
return;
    }
  
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    questions.forEach((question, index) => {
      formDataToSend.append(`question_id[]`, question.id);
      formDataToSend.append(`answer[]`, formData[question.id] || '');
    });

    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        fetchQuestions();
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || 'Failed to update answers');
      }
    } catch (error) {
      console.error('Error updating answers:', error);
      toast.error('Failed to update answers. Please try again later.');
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('main_category_id', "1");
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );
      if (response.data.status === "success") {
        setQuestions(response.data.question || []);
        const initialFormData = {};
        (response.data.question || []).forEach((question) => {
          initialFormData[question.id] = question.answer || '';
        });
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedUpdateAnswer = useCallback(
    debounce(async (questionId, answer) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id[]', questionId);
        formDataToSend.append('answer[]', answer);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                //  toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answer');
            }
        } catch (error) {
            console.error('Error updating answer:', error);
            toast.error('Failed to update answer. Please try again later.');
        }
    }, 2000),
    [uid]
);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Trigger the debounced API call
    debouncedUpdateAnswer(name, value);
};


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card mt-4">
            <div className="card-header">
              <h5>Relationship Wins</h5>
            </div>
            <form>

              <div className="card-body m-3">
                <div className="textnots-container mb-3">
                  <p className="textnots-text">
                    Healing fractured relationships is possible. Start here, only if you are ready to grow, humble yourself, and connect...
                  </p>
                  <p>If you’ve been estranged from a friend or family member for months or years, the mere thought of resuming contact might stir up a lot of uncomfortable emotions. Maintaining relationships with friends and family is an integral step toward preserving your physical and emotional health.  Being lonely or disconnected from others is associated with many health risks, including early mortality.</p>
                  <p>But even if your life is filled with other healthy, happy relationships, any estrangement has the power to create feelings of sadness, loss, anger, betrayal, confusion, helplessness, or anxiety.</p>
                  <p>If you’re ready to reconnect, here are five steps for rekindling an estranged relationship, from what to think about before you reach out to what to say when you do.</p>

                  <strong>Step #1: Put Yourself in the Other Person’s Shoes</strong>
                  <p>That means taking the time to imagine how they might have been hurt by whatever perceived wrongdoing occurred in the relationship.  You have to be able to see how whatever you did or didn’t do could potentially be interpreted as hurtful or offensive.  If you can’t put yourself in the other person’s shoes, you won’t have any success in reconciling with someone who wants distance.  Even if you think the other person started it and you didn’t do anything wrong, you need your understanding of what caused the estrangement to have evolved.

                  </p>

                  <strong>Step #2: Use Coaching or Counseling to Grow as a Person Before Reaching Out </strong>
                  <p>You don’t want to be the same person you were when you became estranged. You want to make sure you’ve evolved in terms of your understanding of yourself and the family dynamics you yourself were raised with.</p>


                  <strong>Step #3: Reach Out and Take Responsibility</strong>
                  <p>A heartfelt apology is often the only way to get through to someone who has checked out of a relationship. You want to take full accountability for your part in the falling out. (Think you’re not at fault in any way? Time to head back to step one. While the “blame” might not always be even, it’s usually not completely one-sided.)</p>
                  <p>You can start with something like, “I’m sorry I haven’t reached out in so long. I’ve missed you. I’ve done a lot of thinking and I’m ready to take responsibility for XYZ.” Do not say “I’m sorry you feel like…” or “I apologize for anything I might have done to hurt you,” as both are unsatisfying, conditional, and may fuel the existing emotional distance.</p>

                  <p>The goal is to come from a place of generosity rather than blame. If you want reconnection, you need to take on the work of making it happen and focus on connecting with them, rather than insisting on justice. Very often, the other person will step up when they see you doing the heavy lifting.</p>
                  <p>Of course, you might decide that justice matters more to you, but that means you may be stuck with separation, at least until the other person relents.</p>

                  <strong>Step #4: Hold the First Meeting in a Neutral, Public Setting (or on the Phone if Not Close in Physical Proximity)</strong>
                  <p>People tend to have better manners in a public space, so there’s less chance of screaming or storming away.  It’s easier for tempers to flare in someone’s living room than it is at an outdoor café, park, or green space.</p>
                  <strong>Step #5: Know That Things May Not Work Out (But There’s Still Some Success in Having Tried)</strong>
                  <p>In many cases, reconciliation will work, and you’ll be on your path to a renewed bond and years of making new memories. Or you may do everything by the book in an effort to repair the relationship, but the other person won’t be ready or interested.</p>
                  <p>Despite that painful reality, at least you’ve put it all out there. There is some peace in accomplishing a goal you set for yourself. You can only control what you say and do. Even if the person says, “Too little, too late,” the process of trying to mend things can ultimately be very cathartic.</p>
                  <p>The effort itself can be particularly empowering for you regardless of the results.  If your attempt doesn’t work, it will be disappointing at first, but with time you will be able to find some forgiveness within yourself.</p>
                </div>
                {questions.map((question) => (
                  <div className="form-group row mb-2" key={question.id}>
                    <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                    <textarea
                      className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                      name={question.id}
                      value={formData[question.id]}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                    {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                  </div>
                ))}
              </div>
              {/* <div className="card-footer text-end">
                <Button type="submit" className="btn btn-success">Save</Button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelationshipWins;
