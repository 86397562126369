import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { debounce } from 'lodash';

const Drivers = () => {

    const uid = localStorage.getItem("user_id");
    const [gratitudeEntries, setGratitudeEntries] = useState([]);
    const [motivationStyle, setMotivationStyle] = useState([]);
    const [motivateConstruct, setMotivateConstruct] = useState([]);
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_primary_diver}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setGratitudeEntries(response.data.data);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);

        }
    };
    const fetchDatamotivationconstrat = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_moved_by_driver}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setMotivateConstruct(response.data.data);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);

        }
    };

    const fetchDatamotivation = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_motivation_diver}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setMotivationStyle(response.data.data);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);

        }
    };

    useEffect(() => {
        fetchData();
        fetchDatamotivation();
        fetchDatamotivationconstrat();
    }, []);

    const columnsPrimaryDriver = [
        // {
        //     name: 'No',
        //     selector: (row, index) => index + 1,
        //     sortable: true,
        // },
        {
            name: 'My Primary Driver',
            selector: (row) => row.my_primary_driver,
            sortable: true,
        },
    ];

    const columnsMotivationStyle = [
        // {
        //     name: 'No',
        //     selector: (row, index) => index + 1,
        //     sortable: true,
        // },
        {
            name: 'My Primary Motivation Style',
            selector: (row) => row.my_primary_motivation_style,
            sortable: true,
        },

    ];

    const columnsMotivateConstruct = [
        // {
        //     name: 'No',
        //     selector: (row, index) => index + 1,
        //     sortable: true,
        // },
        {
            name: 'Moved By',
            selector: (row) => row.moved_by,
            sortable: true,
        },
        {
            name: 'Comforted By',
            selector: (row) => row.comforted_by,
            sortable: true,
        },

    ];
    const [formData, setFormData] = useState({
        Driver: '',

    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const newData = {
                ...prevData,
                [name]: value,
            };
            debouncedApiCall(newData); 
            return newData;
        });
    };
 

    const [formData1, setFormData1] = useState({
        Motivation: '',
    });


    const handleInputChangemotivation = (e) => {
        const { name, value } = e.target;
        setFormData1((prevData) => {
            const newData = {
                ...prevData,
                [name]: value,
            };
            debouncedApiCallmotivation(newData); 
            return newData;
        });
    };

    const [moved, setMoved] = useState({ Moved: '', Comforted: '' });

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        setMoved((prevData) => {
            const newData = {
                ...prevData,
                [name]: value,
            };
            debouncedApiCallmoved(newData); 
            return newData;
        });
    };
    const debouncedApiCall = useCallback(
        debounce(async (newFormData) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('my_primary_driver', newFormData.Driver);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.primary_driver_insert_and_update}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    // toast.success(response.data.message);
                    fetchData(); // Fetch any updated data if necessary
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during API call:", error);
                toast.error('Failed to update. Please try again later.');
            }
        }, 1000), [uid] // Adjust debounce delay as needed
    );
    const debouncedApiCallmotivation = useCallback(
        debounce(async (newFormData) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('my_primary_motivation_style', newFormData.Motivation);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.motivation_style_insert_and_update}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: API.AUTHORIZATION_TOKEN,
                        },
                    }
                );

                if (response.data.status === "success") {
                    // toast.success(response.data.message);
                    fetchDatamotivation(); // Ensure fetchDatamotivation is defined
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during API call:", error);
                toast.error('Failed to update. Please try again later.');
            }
        }, 1000), [uid] // Adjust debounce delay as needed
    );

    const debouncedApiCallmoved = useCallback(
        debounce(async (newMoved) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('moved_by', newMoved.Moved);
            formDataToSend.append('comforted_by', newMoved.Comforted);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.moved_by_insert_and_update}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: API.AUTHORIZATION_TOKEN,
                        },
                    }
                );

                if (response.data.status === "success") {
                    // toast.success(response.data.message);
                    fetchDatamotivationconstrat(); // Ensure this function is defined
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during API call:", error);
                toast.error('An error occurred during submission. Please try again.');
            }
        }, 1000), [uid] // Adjust debounce delay as needed
    );

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className=" align-items-center justify-content-between d-flex">
                                    <h5>Motivations & Drivers</h5>
                                    {/* <Button className="btn-success main-buttons" onClick={togglePopup}>Add My Primary Driver</Button> */}
                                </div>
                            </div>
                        </div>

                        <div className="card-body m-3 ">
                            <div className="textnots-container mb-3">
                                <p className="textnots-text">
                                    <strong>IMPACT</strong>
                                    <p className="textnots-text">
                                        Those who are highly impact-driven want to know they are doing work that is important. They often feel a
                                        sense of destiny, that they are supposed to do something that will create positive change in the world, and they are
                                        usually willing to lead out and can become frustrated if they don’t see the positive outcome of their efforts.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>LEARNING </strong>
                                    <p className="textnots-text">
                                        Those for whom this is a major driver thrive on trying new things and growing. For some, the pursuit
                                        of knowledge is its own goal, while for others the emphasis is on making themselves better at what they do. The
                                        stimulation of making new discoveries and seeking out new information outweighs any hesitation to be seen as a
                                        bit dorky.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>EXCELLING</strong>
                                    <p className="textnots-text">
                                        This motivator leads people to crave the feeling of successfully completing a task, especially when
                                        the bar is set high. They want to feel they’re doing the highest quality work and are meeting or exceeding
                                        expectations. They want to get things done on time and will do pretty much whatever it takes to do so.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>CHALLENGE </strong>
                                    <p className="textnots-text">
                                        This motivator leads people to thrive when tackling difficult issues and overcoming obstacles
                                        or roadblocks. When things get really hard, those motivated by challenge feel they’re at their best. They will
                                        usually keep going no matter what they face, and they revel taking on the perplexing, difficult, or even seemingly
                                        impossible.

                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>DEVELOPING OTHERS</strong>
                                    <p className="textnots-text">

                                        This motivator leads people to enjoy bringing out the best in those around them and
                                        helping others reach their full potential. That may mean mentoring, coaching, managing, or just guiding those
                                        who are closest to them. Sometimes they challenge people with stretch goals, other times they offer constructive
                                        criticism.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>EXCITEMENT</strong>
                                    <p className="textnots-text">
                                        Those strongly driven by this motivator want some sense of adventure and at least a little risk in their
                                        work. No one ever accused them of being boring or shirking opportunities to put themselves out there, and they
                                        get frustrated quickly by stale routines.
                                    </p>
                                </p>
                            </div>
                            <form className="form theme-form">
                                <div className="d-flex justify-content-between">
                                    <div className="form-group col-lg-4 mt-2 mb-3">
                                        <label>My Primary Driver</label>
                                        <select
                                            className={`form-control`}
                                            onChange={handleInputChange}
                                            name="Driver"
                                            value={formData.Driver}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="Impact">Impact</option>
                                            <option value="Learning">Learning</option>
                                            <option value=" Excelling"> Excelling</option>
                                            <option value="Challenge">Challenge</option>
                                            <option value="Developing Others">Developing Others</option>
                                            <option value="Excitement">Excitement</option>
                                        </select>
                                    </div>

                                </div>
                            </form>
                            <DataTable
                                columns={columnsPrimaryDriver}
                                data={gratitudeEntries}
                                persistTableHead={true}
                            />
                        </div>

                    </div>
                </div>


            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>My Primary Motivation Style</h5>
                                </div>

                            </div>
                        </div>

                        <div className="card-body m-3">
                            <div className="textnots-container mb-3">
                                <p className="textnots-text">
                                    <strong>The Thinkers:</strong>
                                    <p className="textnots-text">
                                        Those who are often more creative, who love to learn, enjoy a varied routine, and like to feel an
                                        adrenaline rush now and then. Most Thinkers get frustrated with red tape and bureaucracy, and they want their
                                        work to make an impact on the world around them.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>The Achievers:  </strong>
                                    <p className="textnots-text">
                                        Those who thrive on tight deadlines, tackle ambitious goals, and love a good challenge and
                                        solving a problem. Often type A personalities, Achievers usually like to be in charge of others, or at least in control
                                        of their own destiny.

                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>EXCELLING</strong>
                                    <p className="textnots-text">
                                        This motivator leads people to crave the feeling of successfully completing a task, especially when
                                        the bar is set high. They want to feel they’re doing the highest quality work and are meeting or exceeding
                                        expectations. They want to get things done on time and will do pretty much whatever it takes to do so.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>The Builders:  </strong>
                                    <p className="textnots-text">
                                        Those purpose-driven individuals who are hardwired to develop others and serve those around
                                        them. They cultivate loyal friendships and thrive in strong team environments. The Builders typically believe it’s
                                        important to speak out on significant issues.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>The Caregivers: </strong>
                                    <p className="textnots-text">
                                        Those caring souls who are often more tuned in to others’ emotions. They are more motivated
                                        when they have regular fun at work, and they believe balancing time at work and time with their families is
                                        important.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>The Reward-Driven: </strong>
                                    <p className="textnots-text">
                                        Those who are typically more extrinsically motivated, driven to compete and win prizes—
                                        whether money or applause or the admiration of others. Many of the Reward-Driven believe that the cocktail-party
                                        question, “What do you do?” is extremely important.
                                    </p>
                                </p>
                            </div>
                            <form className="form theme-form">
                                <div className="d-flex justify-content-between">
                                    <div className="form-group col-lg-4 mt-2 mb-3">
                                        <label>My Primary Motivation Style</label>
                                        <select
                                            className={`form-control`}
                                            onChange={handleInputChangemotivation}
                                            name="Motivation"
                                            value={formData1.Motivation}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="The Thinker">The Thinker</option>
                                            <option value="The Achievers">The Achievers</option>
                                            <option value="Excelling">Excelling</option>
                                            <option value="The Builders">The Builders</option>
                                            <option value="The Caregivers">The Caregivers</option>
                                            <option value="The Reward-Driven">The Reward-Driven</option>
                                        </select>
                                    </div>

                                </div>
                            </form>
                            <DataTable
                                columns={columnsMotivationStyle}
                                data={motivationStyle}
                                persistTableHead={true}
                            />
                        </div>

                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Motivation Construct</h5>
                                </div>
                                {/* <div className="col-md-6 text-end">
                                    <button className=" btn btn-success main-buttons" onClick={handleConstruct}>Add Motivation Construct</button>
                                </div> */}
                            </div>
                        </div>
                        <div className="card-body m-3">
                            <div className="textnots-container mb-3">
                                <p className="textnots-text">Those who are typically more extrinsically motivated, driven to compete and win prizes—
                                    whether money or applause or the admiration of others. Many of the Reward-Driven believe that the cocktail-party
                                    question, “What do you do?” is extremely important.
                                </p>
                                <p className="textnots-text">
                                    <strong>Power</strong>
                                    <p className="textnots-text">
                                        These people wear the mantle of leadership, can be controlling and manipulative, but they need not be.
                                        They can wish to empower others.  Seek to influence the choice about what is done, when, how and with whom.
                                        Desire change and see themselves as change agents.  Not satisfied with the status quo in their lives.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>Image </strong>
                                    <p className="textnots-text">
                                        To these people, what counts the most is the way things are done, especially the appearance associated
                                        with the way things are done.  They are good presenters of a message, although they may not have been the
                                        originators of the message.  They enjoy packing people, ideas and things.  Turn their creative ability toward the
                                        visual and the readily apparent.  Very conscious of protocol, manners, appearance, and social acceptability.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>Contribution </strong>
                                    <p className="textnots-text">
                                        This motivator leads people to crave the feeling of successfully completing a task, especially when
                                        the bar is set high. They want to feel they’re doing the highest quality work and are meeting or exceeding
                                        expectations. They want to get things done on time and will do pretty much whatever it takes to do so.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>To determine what you are moved by ask yourself the following: </strong>
                                    <p className="textnots-text">• What excites me?  (Power, Image or Contribution)
                                    </p>
                                    <p className="textnots-text">• What do I seek?  (Power, Image or Contribution)</p>
                                    <p className="textnots-text">• What do I feel is a necessity?  (Power, Image or Contribution)</p>
                                    <p className="textnots-text">• What do I crave?  (Power, Image or Contribution)</p>
                                </p>


                                <p className="textnots-text">
                                    <p className="textnots-text">
                                        Comforters refer to the things with which you have the greatest affinity- the things around which you experience
                                        the greatest sense of ease and familiarity.  Every person is overwhelming comfortable by either people, ideas or
                                        things.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>People</strong>
                                    <p className="textnots-text">
                                        Feels that relationships are far more important than either things or ideas.  Tend to have strong emotions
                                        that they are keen to express, but they rarely allow logic to override their emotions.  Bonding with others is vital to
                                        them.  Loves to be with people and seemingly can’t get enough.  They take time to listen, are genuinely concerned
                                        for the pains and hurts experience by others and have an ability to rejoice with the joyous.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>Ideas </strong>
                                    <p className="textnots-text">
                                        They love strategy and enjoy making paradigms and sketches.  Often very creative, expressing their ideas
                                        in the formats of music, painting, drawing, sculpting, or writing.  They are theorist of the world.  They often appear
                                        to be daydreaming, out of touch or to have poor social and communication skills.
                                    </p>
                                </p>

                                <p className="textnots-text">
                                    <strong>Things  </strong>
                                    <p className="textnots-text">
                                        Likely to feel emotionally at home tinkering in the workshop, sitting at the sewing machine or working on
                                        the family car.  Often restless and uncomfortable unless doing something, generally involving the sense of touch
                                        or the use of hands.  Loves the feel of metal, wood, fabric, solid or objects.  Finds satisfaction in seeing something
                                        take shape, producing a tangible result.
                                    </p>
                                </p>

                            </div>
                            <form className="form theme-form" >
                                <div className="row">
                                    <div className="form-group col-lg-4 mt-2 mb-3">
                                        <label>Moved By</label>
                                        <select
                                            type='text'
                                            className={`form-control`}
                                            onChange={handleInputChange1}
                                            name="Moved"

                                            value={moved.Moved}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="Power">Power</option>
                                            <option value="Contribution">Contribution</option>
                                            <option value="Image">Image</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4 mt-2 mb-3">
                                        <label>Comforted By</label>
                                        <select
                                            type="text"
                                            className={`form-control`}
                                            onChange={handleInputChange1}
                                            name="Comforted"
                                            placeholder='Enter Comforted By'
                                            value={moved.Comforted}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="People">People</option>
                                            <option value="Ideas">Ideas</option>
                                            <option value="Things">Things</option>
                                        </select>
                                   </div>
                                </div>
                            </form>

                            <DataTable
                                columns={columnsMotivateConstruct}
                                data={motivateConstruct}
                                persistTableHead={true}
                            />
                        </div>
                    </div>
                </div>


            </div>



        </>
    );
};

export default Drivers;
