import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import API from "../../utils";
import EditAnswerModal from "./modal/EditAnswerModal";
import "react-toastify/dist/ReactToastify.css";

const LifePlan = () => {
    const uid = localStorage.getItem("user_id");
    const [lifeFactors, setLifeFactors] = useState([]);
    const [lifeFactorQuestion, setLifeFactorQuestion] = useState([]);
    const [answers, setAnswers] = useState({});

    const [tempAnswer, setTempAnswer] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedLifeAreaId, setSelectedLifeAreaId] = useState(null);
    const [selectedLifeAreaQuestionId, setSelectedLifeAreaQuestionId] = useState(null);

    // Function to fetch vertical rows (questions)
    const verticalRow = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.vertialrow}`, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });
            const data = response.data;
            if (data.status === "success") {
                setLifeFactorQuestion(data.life_area_vertical);
            } else {
                console.error("Failed to fetch vertical rows");
            }
        } catch (error) {
            console.error("Error fetching vertical rows:", error.message);
        }
    };


    const horizontalRow = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.horizontalrow}`, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });
            const data = response.data;
            if (data.status === "success") {
                setLifeFactors(data.life_area);
            } else {
                console.error("Failed to fetch horizontal rows");
            }
        } catch (error) {
            console.error("Error fetching horizontal rows:", error.message);
        }
    };

    // Function to fetch answers from the API
    const fetchAnswers = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.getAnswer}/${uid}`, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });
            const data = response.data;
            if (data.status === "success") {
                // Convert array of answers into an object for easy access
                const formattedAnswers = {};
                data.data.forEach(answer => {
                    const key = `${answer.life_area_id}-${answer.life_vertical_id}`;
                    formattedAnswers[key] = answer.text;
                });
                setAnswers(formattedAnswers);
            } else {
                console.error("Failed to fetch answers");
            }
        } catch (error) {
            console.error("Error fetching answers:", error.message);
        }
    };

    useEffect(() => {
        horizontalRow();
        verticalRow();
        fetchAnswers();
    }, []);

    // Function to handle editing an answer
    const handleEdit = (lifeAreaId, lifeVerticalId, currentAnswer) => {
        setSelectedLifeAreaId(lifeAreaId);
        setSelectedLifeAreaQuestionId(lifeVerticalId); // Assuming this is the correct ID
        setTempAnswer(currentAnswer);
        setShowModal(true);
    };

    // Function to handle changing the temporary answer
    const handleChange = (event) => {
        setTempAnswer(event.target.value);
    };

    // Function to save the edited answer
    const handleSave = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid); 
        formDataToSend.append('life_area_id', selectedLifeAreaId);
        formDataToSend.append('life_question_id', selectedLifeAreaQuestionId); // Assuming this is the correct ID
        formDataToSend.append('text', tempAnswer);
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.editAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    }
                });

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                fetchAnswers(); // Refresh answers after saving
                setShowModal(false);
            } else {
                console.error("Failed to update answer");
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    // Function to handle closing the modal
    const handleClose = () => {
        setShowModal(false);
    };

    // Function to get the answer based on lifeAreaId and lifeVerticalId
    const getAnswer = (lifeAreaId, lifeVerticalId) => {
        const key = `${lifeAreaId}-${lifeVerticalId}`;
        return answers[key] || '';
    };


    

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header">
                                    <h5>Life Plan</h5>
                                </div>
                                <div className="mt-4 life-planing-div">
                                    <table className="life-planing-table table table-bordered">
                                        <thead>
                                            <tr>
                                                <th >Family life Area</th>
                                                {lifeFactors.map((item, index) => (
                                                    <th key={index}>{item.name}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lifeFactorQuestion.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center life-plan-question life-plan-table-width">{item.question}</td>
                                                    {lifeFactors.map((factor, i) => (
                                                        <td
                                                            key={i}
                                                            onClick={() => handleEdit(factor.id, item.id, getAnswer(factor.id, item.id))}
                                                            className={`text-center ${getAnswer(factor.id, item.id) === '0' ? 'red-bg' : ''}`}
                                                        >
                                                            {getAnswer(factor.id, item.id)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Answer Modal */}
            <EditAnswerModal 
                show={showModal}
                handleClose={handleClose}
                tempAnswer={tempAnswer}
                handleChange={handleChange}
                handleSave={handleSave}
            />

       
        </Fragment>
    );
};

export default LifePlan;
