import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';
const Personalmanifesto = () => {
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState({});
    const uid = localStorage.getItem('user_id') || '';

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "5");
        formDataToSend.append('sub_category_id', "22");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    
  const debouncedUpdateAnswer = useCallback(
    debounce(async (questionId, answer) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id[]', questionId);
        formDataToSend.append('answer[]', answer);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                //  toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answer');
            }
        } catch (error) {
            console.error('Error updating answer:', error);
            toast.error('Failed to update answer. Please try again later.');
        }
    }, 2000),
    [uid]
);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Trigger the debounced API call
    debouncedUpdateAnswer(name, value);
};
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>My Personal Manifesto Statements</h5>
                    </div>
                    <div className="card-body m-3">
              <div className="textnots-container ">
                   
                   <p className="textnots-text">
                   Time to inspire your people. Write your own manifesto so you can get you thepassionate, devoted fans you really need to turn your brand into a powerhouse and turn your vision into reality.
                   </p>
                                   
                 </div>
                 {questions.map((question) => (
            <div className="form-group row mx-2 my-3" key={question.id}>
                <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                <textarea
                    className="form-control"
                    name={question.id}
                    value={formData[question.id]}
                    onChange={handleChange}
                    rows="4"
                ></textarea>
            </div>
        ))}
                       </div>
                </div>
            </div>
        </div>
    );
};

export default Personalmanifesto;
