import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const Perpose = ({ activeTab }) => {
    const uid = localStorage.getItem('user_id') || '';

    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

 

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '2');
        formDataToSend.append('sub_category_id', '5');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    const handleSubmitFamily = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[${index + 1}]', questionId);
            formDataToSend.append('answer[${index + 1}]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Trigger the debounced API call
        debouncedUpdateAnswer(name, value);
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Discovering Your Life Purpose 
</h5>
                    </div>
                    <form className="form theme-form" noValidate onSubmit={handleSubmitFamily}>
                        <div className="card-body m-3">
                            {/* <div className="textnots-container-valueandpurpose mb-3">
                                <p className="textnots-text">
                                    Crafting a life purpose is a fun and enlightening activity that translates your core values into action. In fact, thinking critically about what you want to live for and how you’re going to accomplish it helps build the momentum and motivation you’ll need to reach your goals. So, let’s keep going.
                                </p>
                                <p className="textnots-text">
                                    When looking at your life purpose you should do so in four dimensions: personal, work, family, and community. Thinking about your purpose this way plays a key role in helping you balance different, and sometimes competing parts of your life (e.g. family and work). Pick one and start composing your purpose by thinking about your answers to the thought-starter questions we’ve provided below.
                                </p>
                                <p className="textnots-text">
                                    And remember, don’t worry about getting everything exactly right. Going back to revise or completely change your purpose is a natural part of personal growth. Even revisiting your core values and thinking about how those fit into your life can help generate new thoughts around purpose.
                                </p>
                                <p className="textnots-text">
                                    This is your purpose worksheet. Take as long as you need to get it where it feels really right to you. Also, it’s fine to focus on just one dimension if you’d prefer. Whatever works best for you.
                                </p>
                            </div> */}
                            <div className="row">
                                <div className="col-sm-12">
                                    {questions.map((question) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                                            <textarea
                                                className="form-control"
                                                name={question.id}
                                                value={formData[question.id] || ''}
                                                onChange={handleChange}
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="card-footer">
                            <button type="submit" className="btn btn-success main-buttons me-1 float-end">
                                Save
                            </button>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Perpose;
