import { Home, Box,  Airplay, FolderPlus, Command, Cloud, FileText, Server, Image } from "react-feather";

export const MENUITEMS = [
  // ****** not use ******

  // {
  //   title: "Dashboard",
  //   icon: Home,
  //   type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     { path: "/dashboard/default", title: "Default", type: "link" },
  //     { path: "/dashboard/ecommerce", title: "E-Commerce", type: "link" },
  //     { path: "/dashboard/university", title: "University", type: "link" },
  //     { path: "/dashboard/crypto", title: "Crypto", type: "link" },
  //     { path: "/dashboard/server", title: "Server", type: "link" },
  //     { path: "/dashboard/project", title: "Project", type: "link" },
  //   ],
  // },

  {
    title: "Home",
    icon: Home,
    path: "/dashboard/default",
    type: "link",
    active: false,
    // children: [
    //   { path: "/dashboard/default", title: "Dashboard", type: "link" },
    //   { path: "/home/life-plan", title: "Life Plans", type: "link" },
  
    // ],
  },
  {
    title: "Personal Growth",
    icon: Airplay,
    type: "sub",
    active: false,
    children: [
      { path: "/personal-growth/task", title: "Tasks", type: "link" },
      { path: "/personal-growth/daily-gratitude", title: "Daily Gratitude", type: "link" },
      { path: "/personal-growth/daily-affirmation", title: "Daily Affirmations", type: "link" },
      { path: "/personal-growth/habit-tracker", title: "Habit Tracker", type: "link" },
      { path: "/personal-growth/key-relationship", title: "Key Relationships", type: "link" },
      { path: "/personal-growth/annual-goals-outcomes", title: "Goals & Outcomes", type: "link" },
    ],
  },

  // {
  //   title: "Personal Growth",
  //   icon: Box,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/personal-growth/task", title: "Tasks", type: "link" },
  //     { path: "/personal-growth/daily-gratitude", title: "Daily Gratitude", type: "link" },
  //     { path: "/personal-growth/daily-affirmation", title: "Daily Affirmations", type: "link" },
  //     { path: "/personal-growth/habit-tracker", title: "Habit Tracker", type: "link" },
  //     { path: "/personal-growth/key-relationship", title: "Key Relationships", type: "link" },
  //     { path: "/personal-growth/annual-goals-outcomes", title: "Annual Goals & Outcomes", type: "link" },
  //   ],
  // },
  {
    title: "My Life Plan",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      { path: "/mylifeplan/lifeingeneral", title: "Life in General", type: "link" },
      { path: "/mylifeplan/physicalhealth", title: "Physical Health", type: "link" },
      { path: "/mylifeplan/mentalhealth", title: "Mental Health", type: "link" },
      { path: "/mylifeplan/romanticlife", title: "Romantic Life", type: "link" },
      { path: "/mylifeplan/personalfinances", title: "Personal Finances", type: "link" },
      { path: "/mylifeplan/family", title: "Family", type: "link" },
      { path: "/mylifeplan/sociallife", title: "Social Life", type: "link" },
      { path: "/mylifeplan/career", title: "Career", type: "link" },
      { path: "/mylifeplan/business", title: "Business", type: "link" },
      { path: "/mylifeplan/personal-purpose-mission", title: "Personal Purpose & Mission", type: "link" },
      { path: "/mylifeplan/personalgrowth", title: "Personal Growth", type: "link" },
      { path: "/mylifeplan/homeenvironment", title: "Home Environment", type: "link" },
      { path: "/mylifeplan/funrecreation", title: "Fun & Recreation ", type: "link" },
      // { path: "/mylifeplan/Passionsbobbies", title: "Passions & Hobbies", type: "link" },
      { path: "/mylifeplan/travel", title: "Travel", type: "link" },
      { path: "/mylifeplan/spiritual", title: "Spiritual", type: "link" },
    ],
  },
  {
    title: "Personal OS",
    icon: FolderPlus,
    type: "sub",
    active: false,
    children: [
      { path: "/life360/mycorevalues", title: "My Core Values ", type: "link" },
      { path: "/life360/myvisionstatement", title: "My Vision Statement", type: "link" },
      { path: "/life360/mypurpose", title: "My Purpose & Mission", type: "link" },
      // { path: "/life360/myvision", title: "My Vision", type: "link" },
      // { path: "/life360/mymission", title: "My Mission ", type: "link" },
      { path: "/life360/mypassion", title: "My Passion ", type: "link" },
      // { path: "/life360/those-i-want-to-serve", title: "Those I Want to Serve", type: "link" },
      // { path: "/life360/mylifemotto", title: "My Life Motto", type: "link" },
      // { path: "/life360/mypromisestomyself", title: " My Promises to Myself", type: "link" },
      { path: "/life360/selfreflection", title: "Self Reflection", type: "link" },
      { path: "/life360/myidentity", title: "My Identity", type: "link" },
      { path: "/life360/mypersonality", title: "My Personality ", type: "link" },
      { path: "/life360/things-i-love-hate", title: "Things I Love/Hate", type: "link" },
      { path: "/life360/personal-swot", title: "Personal SWOT", type: "link" },
      { path: "/life360/personal-branding", title: "Personal Branding ", type: "link" },
      { path: "/future/Personalbio", title: "Personal Bio", type: "link" },
    ],
  },
  {
    title: "My Belief System",
    icon: Command,
    type: "sub",
    active: false,
    children: [
      { path: "/my-belief/belief-system", title: "Core Belief System", type: "link" },

      { path: "/my-belief/define-success", title: " Beliefs on Success", type: "link" },
     
    ],
  },
  {
    title: "My Reality",
    icon: Cloud,
    type: "sub",
    active: false,
    children: [
      { path: "/my-reality/life-wheel", title: "Life Wheel", type: "link" },
      { path: "/my-reality/life-assessment", title: "My Life Assessment", type: "link" },
      // { path: "/my-reality/altimate-you", title: "The Ultimate Assessment", type: "link" },
      // { path: "/my-reality/my-personal-finance", title: "Personal Finances", type: "link" },
      // { path: "/my-reality/life-line", title: "Lifeline / Milestones", type: "link" },
    ],
  },
  {
    title: "My Future",
    icon: Image,
    type: "sub",
    active: false,
    children: [
      { path: "/future/RelationshipWins", title: "Relationship Wins", type: "link" },
      { path: "/future/RoleModel", title: "Role Models", type: "link" },
      { path: "/future/Obstacles", title: "Life’s Obstacles & Fear", type: "link" },
      { path: "/future/Bigdata", title: "My Very BIG Dream", type: "link" },
      { path: "/future/MyLegacy", title: "My Legacy", type: "link" },
      { path: "/future/life-menifesto", title: " My Life Manifesto", type: "link" },

      // { path: "/future/life-goal", title: "Life Goals", type: "link" },

      { path: "/future/vision-board", title: "My Vision Board", type: "link" },

      { path: "/future/bucket-list", title: "My Bucket List", type: "link" },
      { path: "/future/perfect-day", title: "My Perfect Day", type: "link" },


    ],
  },
  {
    title: "My Reports",
    icon: FileText,
    type: "sub",
    active: false,
    children: [
      {
        path: "/my-reports/LifePlanProgress",
        title: "Life Plan Progress",
        type: "link"
        
      },

      {
        path: "/my-reports/this-year-trategic-plan",
        title: "This Year’s Strategic Plan",
        type: "link"
       
      },
      {
        path: "/my-reports/life-360-id",
        title: "My Life Plan",
        type: "link"
        
      },
      // {
      //   title: "Formik Form ",
      //   type: "sub",
      //   children: [
      //     { path: "/formikform/basicinput", type: "link", title: "Basic Input" },
      //   ],
      // },
    ],
  },
  {
    title: "My Notes",
    icon: Server,
    type: "link",
    active: false,
    path:"/notes",
    // children: [
    //   {
    //     path: "/my-notes/personal-learning-path",
    //     title: "Personal Learning Path ",
    //     type: "link"
       
    //   },
    //   {
    //     path: "/my-notes/knowledg-library",
    //     title: " Knowledge Library",
    //     type: "link",
    //   },
    //   {
    //     title: " Thoughts & Ideas",
    //     path: "/my-notes/thought-idea",
    //     type: "link",
    //   },
    // ],
  },

];
