import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';
import lifemanifesto from "../../../../src/assets/images/avtar/imgpsh_fullsize_anim.jpg";

const Mylifemanifesto = () => {
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState({});
    const uid = localStorage.getItem('user_id') || '';

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[]`, question.id);
            formDataToSend.append(`answer[]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "5");
        formDataToSend.append('sub_category_id', "21");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    
  const debouncedUpdateAnswer = useCallback(
    debounce(async (questionId, answer) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id[]', questionId);
        formDataToSend.append('answer[]', answer);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                //  toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answer');
            }
        } catch (error) {
            console.error('Error updating answer:', error);
            toast.error('Failed to update answer. Please try again later.');
        }
    }, 2000),
    [uid]
);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Trigger the debounced API call
    debouncedUpdateAnswer(name, value);
};
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>My Life Manifesto</h5>
                    </div>
                    <form>

<div className="card-body m-3">
<div className="textnots-container mb-3">

    <p className="textnots-text">
      <strong>What is a Personal Manifesto?</strong>
    </p>
    <p className="textnots-text">
      Your personal manifesto is a description of your authentic self, including your beliefs, strengths, values, and passions. It is like a bio in that it represents who you are, but it does not include things like qualifications, or personal interests
    </p>
    <strong>Why a Manifesto?</strong>
    <p className="textnots-text">
      Most organizations have manifestos — a clear directive of who they are and why they exist. Without one, their customers are less likely to trust them, and their employees don’t know what they’re striving for. That manifesto guides the company in making decisions and helps them in their branding. The personal manifesto is a like a compass for the soul. It helps us determine our purpose in life, make decisions and it serves as a reminder of who we are when life becomes difficult, and we lose sight of our true selves.
    </p>
    <p className="textnots-text"><strong>Writing Your Full Manifesto</strong></p>
    <p className="textnots-text">
      Use the notes you made during the writing exercises in Module 4. You’ll need your list of 6-8 (or so) values, your character sketch detailing your strengths, and the notes you made when you brainstormed words around your purpose. During the 20-minute writing period you’ll use these notes to write a description of who you are. Aim for 200-300 words if you can and then during the discussion time, we’ll see what you’ve got for your first draft. If you would like to use headings, here are some that may help:
    </p>
    <p className="textnots-text"><strong>Tips:</strong></p>
    <p className="textnots-text">• Keep it positive – remember this is your best self on display</p>
    <p className="textnots-text">• Remember, your manifesto may not represent who you are on a daily basis, or your behavior, but rather who you are at the core and what makes you come alive.</p>
    <p className="textnots-text">• Use strong language – no wishy-washy verbs</p>
    <p className="textnots-text">• It can be as short or as long as you like (but don’t make it so long that you can’t read it every day).</p>
    <p className="textnots-text"><strong>This Personal Manifesto Includes:</strong></p>
    <p><strong>1. Your Vision and Mission </strong><p>- What you enjoy, your passion, what you can do best. What you want to contribute of value to the world. And the lifestyle you want to enjoy as a result of doing this.</p></p>
    <p><strong>2. Your Goals </strong>- Long, medium, and short term goals: Where you want to get to.</p>
    <p><strong>3. Where you are right now in life.</strong> - What you are currently doing.</p>
    <p><strong>4. What’s holding you back at the moment?</strong> - Current problems: Obstacles, difficulties, hurdles.</p>
    <p><strong>5. Your Action Plan </strong>- What you are going to do to reach your goals and overcome the obstacles: Actions and Time Plan</p>
    <p><strong>6. Your Personal Declaration </strong>- What you are going to achieve within the next 3 to 5 years, and the actions you are going to take in order to achieve it. The first section contains notes on each of these items. The second section contains the templates for you to complete.</p>

    <div className="text-center">
  <img src={lifemanifesto} className="w-75 h-50" ></img>
  </div>
  </div>

  <table className="table table-bordered">
    <thead>
      <tr>
        <th scope="col" style={{ width: '20%' }}>Question </th>
        <th scope="col" style={{ width: '65%' }}>Answer </th>
      </tr>
    </thead>
    <tbody>
      {questions.map((question) => (
        <tr key={question.id}>
          <td className="annualoutcome"><b>{question.question}</b></td>
          <td className="annualoutcome">
            <textarea
              className="form-control vision-text-area"
              name={question.id}
              placeholder='Insert Here...'
              value={formData[question.id]}
              onChange={handleChange}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>

 
</div>

</form>
                </div>
            </div>
        </div>
    );
};

export default Mylifemanifesto;
