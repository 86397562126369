import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const Assessment = ({ activeTab }) => {
    const uid = localStorage.getItem('user_id') || '';

    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        setValidated(false);
        setErrors({});
    }, [activeTab]);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '7');
        formDataToSend.append('sub_category_id', '14');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                const initialWordCounts = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                    initialWordCounts[question.id] = countWords(question.answer || '');
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const debouncedSubmit = useCallback(
        debounce(async (newFormData) => {
            const formDataToSend = new FormData();
            questions.forEach((question, index) => {
                formDataToSend.append(`question_id[${index + 1}]`, question.id);
                formDataToSend.append(`answer[${index + 1}]`, newFormData[question.id] || '');
            });

            formDataToSend.append('user_id', uid);
            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    //toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answers');
                }
            } catch (error) {
                console.error('Error updating answers:', error);
                toast.error('Failed to update answers. Please try again later.');
            }
        }, 1000), [questions]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormData((prevData) => {
            const newFormData = {
                ...prevData,
                [name]: value,
            };
            debouncedSubmit(newFormData); // Trigger the debounced API call
            return newFormData;
        });
      
    };

    const countWords = (text) => {
        return text.split(/\s+/).filter((word) => word.length > 0).length;
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Giftings</h5>
                    </div>
                    <form className="form theme-form" noValidate validated={validated}>
                        <div className="card-body m-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Gifting</th>
                                                <th scope="col">%</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {questions.map((question) => (
                                                <tr key={question.id}>
                                                    <td>
                                                        <label className="col-sm-12 col-form-label mb-2 p-0 d-flex align-items-center">
                                                          <b>  {question.question}</b>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                className={`form-control text-center ${errors[question.id] ? 'is-invalid' : ''}`}
                                                                name={question.id}
                                                                type="text"
                                                                value={formData[question.id] || ''}
                                                                onChange={handleChange}
                                                                style={{ width: '80px' }} 
                                                            />
                                                            <span className="mx-1 percentage">%</span>
                                                        </div>
                                                        {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Assessment;
