import React, { useCallback, useEffect, useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { debounce } from "lodash";
const CoreBeliefBreakdown = () => {
  const uid = localStorage.getItem('user_id') || '';
  const [errors, setErrors] = useState({});
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    fetchQuestions();
    const clearErrors = () => setErrors({});
    window.addEventListener('clearValidationErrors', clearErrors);

    return () => {
      window.removeEventListener('clearValidationErrors', clearErrors);
    };
  }, []);

  const fetchQuestions = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('main_category_id', '2');
    formDataToSend.append('sub_category_id', '4');

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getquestionbymainandsub}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === 'success') {
        setQuestions(response.data.question);
        const initialFormData = {};
        (response.data.question || []).forEach((question) => {
          initialFormData[question.id] = question.answer || '';
        });
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message || 'Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast.error('Failed to fetch questions. Please try again later.');
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: '',
  //   }));
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };


  const debouncedUpdateAnswer = useCallback(
    debounce(async (questionId, answer) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id[${index + 1}]', questionId);
        formDataToSend.append('answer[${index + 1}]', answer);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addupdateanswermybelief}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                //  toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answer');
            }
        } catch (error) {
            console.error('Error updating answer:', error);
            toast.error('Failed to update answer. Please try again later.');
        }
    }, 2000),
    [uid]
);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Trigger the debounced API call
    debouncedUpdateAnswer(name, value);
};


  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Core Belief System </h5>
            </div>
            <form className="form theme-form">
              <div className="card-body m-3">
                <div className="textnots-container-persomality mb-3">
                  <strong>Your core beliefs fall into three main camps:</strong><br></br>
                  <strong>Beliefs About Yourself</strong>
                  <p className="textnots-text">These tend to inform the way you understand your own self-worth. If you experienced harsh criticism, neglect or abuse as a child, you may have learned to think of yourself as weak or inadequate.</p>
                  <strong>Beliefs About Other People:</strong>
                  <p className="textnots-text">These also frequently have their roots in early life. Again, if you suffered traumas or very negative treatment from others you may adopt the belief that people are dangerous or untrustworthy. This is how racism and sexism is kept alive and well.</p>
                  <strong>Beliefs About Life and the World</strong>
                  <p className="textnots-text">These determine your general attitudes. If you grow up in a deprived or unpredictable environment, you may develop negative beliefs about the world and life. Beliefs that the world or life is dangerous, cruel, or unfair are examples of the kinds of beliefs negative life conditions may give rise to. Healthy core beliefs about the world, yourself and other people may include ‘the world is mostly a good place’ or ‘most people are decent’ and ‘I’m a worthwhile person.’ Positive early experiences of life conditions, parents, and other family are likely to give rise to healthy core beliefs. Using the following, you can identify your core beliefs and how they may be influencing your understanding of a recent event in your life. Getting familiar with your beliefs and their effects is the first step in changing them to more healthy positive alternatives.</p>
               
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {questions.map((question) => (
                      <div className="form-group row mb-2" key={question.id}>
                        <label className="col-sm-12 col-form-label  mb-2 p-0"><b>{question.question}</b></label>
                        <textarea
                          className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                          name={question.id}
                          value={formData[question.id] || ''}
                          onChange={handleChange}
                          rows="4"
                        ></textarea>
                        {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                      </div>
                    ))}


                  </div>
                </div>
              </div>

              {/* <div className="card-footer">
                <button type="submit" className="btn btn-success main-buttons float-end">
                  Save
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );

}

export default CoreBeliefBreakdown;
